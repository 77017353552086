.section {
  margin: 2rem 0;
  width: 100%;
}
.all-ceo-description-about {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto 2rem;
}
.ceo-container-description-about {
  text-align: center;
  width: 100%;
}
.ceo-container-description-about h2 {
  color: #02203c;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  line-height: 1.3;
  font-size: 35px;
  font-style: italic;
}
.ceo-container-description-about h4 {
  font-weight: 800 !important;

  color: #fff !important;
  text-align: center;
  line-height: 2.5;
}
.ceo-picture-sociaux-about {
}
.ceo-container-about {
  width: 500px;
  height: 500px;
  margin: 1.5rem auto;
}
.ceo-container-about img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.3);
}

.container-sociaux-links {
  margin: 0 auto !important;
}
.container-sociaux-links-ceo-about {
  max-width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .ceo-background-about {
  width: 50%;
  max-width: 95%;
} */
.ceo-background-about p {
  color: #d1dbe6;
  line-height: 1.8;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
/* start media query */
@media only screen and (min-width: 1920px) {
}
@media only screen and (max-width: 1536px) {
}

@media only screen and (max-width: 1281px) {
  .all-ceo-description-about {
    width: 95% !important;
    flex-direction: column !important;
  }
  .unique-employees-description-about {
    max-width: 100% !important;
  }
  .ceo-container-description-about {
    max-width: 100% !important;
  }
  .ceo-container-description-about h2 {
    line-height: 1.5;
    font-size: 30px;
  }
  .ceo-container-description-about h4 {
    font-weight: 400 !important;
    text-align: center;
  }

  .ceo-container-about {
    width: 450px;
    height: 500px;
  }
  .ceo-background-about p {
    font-size: 25px;
  }
  .container-sociaux-links {
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 1025px) {
  .ceo-background-about p {
    font-size: 25px;
  }
  .container-align-links-team-about a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 913px) {
  .all-ceo-description-about {
    display: grid;
    width: 100%;
    margin: 2rem auto;
  }
  .ceo-container-about {
    width: 800px;
    height: 800px;
  }
  .ceo-background-about {
    width: 95%;
    max-width: 100%;
    margin: 2rem auto 0;
  }
  .ceo-background-about p {
    font-size: 35px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 25px;
  }
}

@media only screen and (max-width: 821px) {
}

@media only screen and (max-width: 769px) {
  .ceo-container-about {
    width: 700px;
    height: 700px;
  }
}

@media only screen and (max-width: 541px) {
  .ceo-container-about {
    width: 450px;
    height: 450px;
  }
  .ceo-background-about p {
    font-size: 22px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 415px) {
  .ceo-container-about {
    width: 400px;
    height: 400px;
  }
  .ceo-background-about p {
    font-size: 25px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 9px;
  }
}

@media only screen and (max-width: 394px) {
  .ceo-container-about {
    width: 375px;
    height: 375px;
  }
  .ceo-background-about p {
    font-size: 20px;
  }
  .ceo-container-description-about {
    text-align: center;
    width: 100%;
  }
  .container-align-links-team-about a {
    font-size: 9px;
  }
}

@media only screen and (max-width: 376px) {
  .ceo-container-about {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 361px) {
  .ceo-container-about {
    width: 340px;
    height: 340px;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 8px;
  }
}
@media only screen and (max-width: 281px) {
  .ceo-container-about {
    width: 230px;
    height: 230px;
    margin: 0 auto;
    text-align: center;
  }
  .ceo-container-description-about {
    max-width: 90% !important;
  }

  .ceo-container-description-about h2 {
    /* color: #89c6ff; */
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    line-height: 2.5 !important;
    font-size: 15px;
    font-style: italic;
  }
  .ceo-container-description-about h4 {
    line-height: 1.2;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    max-width: 90%;
  }
  .ceo-container-description-about p {
    font-size: 15px;
  }
  .container-sociaux-links-ceo-about {
    max-width: 95%;
    text-align: center !important;
  }
  .ceo-picture-description-about {
    max-width: 90%;

    padding: 0 !important;
  }
  .ceo-background-about {
    width: 95%;
    margin: 0 auto;
  }
  .ceo-background-about p {
    font-size: 15px;
  }
  .container-align-links-team-about {
    width: 60%;
  }
  .container-align-links-team-about .btn-primary {
    font-size: 5px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
}
/* end of media query*/


* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* code snippet code */
.code-block {
  position: relative;
  margin: 20px;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
}
/*code snippet code */
.body-navbar {
  margin-top: 100px;
  width: 100vw;
}

textarea {
  white-space: pre-line !important ;
}
.errorReview {
  color: red !important;
  font-size: 15px !important;
  margin: 0 auto;
  text-align: center;
  margin-left: 10% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.successReview {
  color: green !important;
  font-size: 15px !important;
  margin: 0.5rem auto;
  text-align: center;
  text-align: left;

  padding: 0 !important;
}

:root {
  /* dark shades of primary color*/
  /* --clr-primary-1: hsl(43, 86%, 17%); */
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  --clr-primary-5: #2caeba;
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-green-dark: hsl(125, 67%, 35%);
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-white: #fff;
  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.5s linear;
  --spacing: 0.2rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 700px;
}

:root {
  --primaryColor: #af9a7d;
  /* --primaryColor:#0e2439; */
  --secondPrimaryColor: #0af;
  /* --primaryColor:blue; */
  /* --primaryColor: #f69314; */
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.5s ease-in-out;
  --clr-grey-10: hsl(210, 36%, 96%);
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-primary-5: #2caeba;
  --clr-grey-9: hsl(212, 33%, 89%);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --radius: 0.25rem;
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* start of the global style */
body {
  /* padding-top: 100px; */
  color: #fff;
  color: var(--mainWhite);
  background-color: #f6f9fc;
  background-color: #0e2439;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
  scroll-behavior: smooth;
  cursor: vertical-text;
}

.scroll {
  overflow: hidden;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
  /* color: #0b1332;
  font-family: "Qanelas Soft Bold" !important; */
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75;
  /* text-align: left; */
}
.opacity {
  opacity: 0.2;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2rem;
  line-height: 1.25;
  margin-bottom: 1.25;
  color: #111;
  color: #fff;
  text-align: center;
  flex: 0, 1 10px;
}
h5 {
  font-size: 1em;
  font-weight: bold;

  /* margin-bottom: 1.5em; */
}
h6 {
  font-size: 1em;
  font-weight: bold;
  /* margin-bottom: 1.5em; */
}
.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: #222;
  color: var(--mainBlack);
  background: #af9a7d;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  margin: 2rem auto;
  text-align: center;
}
.tutorial,
.blog,
.project {
  display: block;
  margin-bottom: 2rem;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}

.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}

.btn-primary:hover {
  background: transparent;
  color: #af9a7d;
  color: var(--primaryColor);
}
.section-title {
  text-align: center;
  margin-bottom: 1rem;
  /* padding-top: 100px; */
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: #111;
  color: #fff;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background-color: #af9a7d;
  background-color: var(--primaryColor);
}
a {
  text-decoration: none;
  color: #af9a7d;
  color: var(--primaryColor);

  color: #4799eb;
}

/* end of the global style */

/* start of the navbar style */

/* .container-navBar {
  width: 100vw;
} */
.navbar {
  width: 100vw;
  width: 100%;
  position: fixed;
  height: 100px;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  align-items: center;
  background: linear-gradient(-90deg, #02203c, #001528);
  background: transparent;
  display: none;
  z-index: 80;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  /* position: relative; */
}

.active-navbar {
  background: linear-gradient(-90deg, #02203c, #001528);
  display: flex;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.nav-center {
  width: 90%;
  margin: 0 auto;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-between;

  align-items: center;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  display: block;
  font-size: 2rem;
  color: #fff;
  color: var(--mainWhite);
  height: auto-fit;
}
.nav-icon-logo {
  font-size: 2rem;
  color: #fff;
  color: var(--mainWhite);
  transition-duration: 1.5s;
  cursor: pointer;
}
.nav-icon-logo:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  transition-duration: 1.5s;
}

.nav-icon-login {
  font-size: 0.9rem;
  text-transform: capitalize;
}
.nav-icon-login-desktop {
  display: block;
  color: #fff;
  color: var(--mainWhite);
  height: auto-fit;
  font-size: 0.9rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;
}
/* start of logo-container style */
.container-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.container-logo:hover {
  opacity: 0.5;
}
.container-logo-symbol {
  background-color: #fff;
  padding: 1.5%;
}
.container-logo-symbol span {
  color: #111;
  font-weight: 600;
}
.container-logo-title {
  margin-left: 5%;
  /* display: none; */
}
@media only screen and (max-width: 281px) {
  .nav-center {
    width: 95%;
    margin: 0 auto;
  }
  .container-logo-title {
    display: none;
  }
}
.container-logo-title span {
  font-size: 1rem;
  font-family: Jura, Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 700;
}
/* end of logo-container style */
/* start of the link style */
.container-nav-links {
  display: none;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.nav-links li {
  list-style-type: none;
}

.nav-links li a {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  color: #fff;
  color: var(--mainWhite);
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 800;
}

.nav-links li:nth-child(n)::after {
  content: "";
  display: block;
  border-bottom: 1px solid #fff;
  border-bottom: 1px solid var(--mainWhite);
  width: 0%;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.nav-links li:nth-child(n):hover::after {
  width: 100%;
}
/* end of the logo container sytle */

/* start of the container-login-signup */
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.container-login-signup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.container-login-signup button {
  font-size: 16px;
  padding: 5%;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-login-signup button:hover {
  font-size: 15px;
  opacity: 0.7;
}
.container-login-signup button:nth-child(1) {
  color: #fff;
  font-size: 22px;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
/* .container-login-signup button:nth-child(2) {
  background: var(--primaryColor);
  color: black;
  margin-left: 10%;
} */
/* @media only screen and (max-width: 280px) {
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container-login-signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .container-login-signup button {
    font-size: 16px;
    padding: 5%;
    transition: var(--mainTransition);
  }
  .container-login-signup button:hover {
    font-size: 15px;
    opacity: 0.7;
  }
  .container-login-signup button:nth-child(1) {
    background: white;

    color: black;
  }
  .container-login-signup button:nth-child(2) {
    background: var(--primaryColor);
    color: black;
   
  }
} */
/* end of the container-login-signup */

.sociauxLink-top {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  height: 100%;
}

.container-sociaux-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 237px;
  height: auto;
  margin: 1.5rem 0;
}
.container-image-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 27px;
  height: 27px;
  translate: all 0.5s ease-in-out;
  translate: var(--mainTransition);
}
.container-image-link:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.container-image-link img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  transition: none 0s ease 0s;
  opacity: 1;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
/* .container-sociaux-links a {
  opacity: 0;
  animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.container-sociaux-links a:nth-of-type(1) {
  animation-delay: 0.25s;
}
.container-sociaux-links a:nth-of-type(2) {
  animation-delay: 0.5s;
}
.container-sociaux-links a:nth-of-type(3) {
  animation-delay: 0.75s;
}
.container-sociaux-links a:nth-of-type(4) {
  animation-delay: 1s;
}
.container-sociaux-links a:nth-of-type(5) {
  animation-delay: 1.25s;
}
.container-sociaux-links a:nth-of-type(6) {
  animation-delay: 1.5s;
}
.container-sociaux-links a:nth-of-type(7) {
  animation-delay: 1.75s;
} */
@media screen and (min-width: 1000px) {
  .nav-center {
    width: 95%;
    margin: 0 auto;
  }

  .nav-btn {
    display: none;
  }
  .container-nav-links {
    width: 50%;
  }
  .nav-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .nav-links li:nth-child(n + 2) {
    margin-left: 3%;
  }
  .navContent-menu {
    width: 100%;
    height: 170px;
    background: #fff;
    position: absolute;
    top: 200%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    /* transform: translateX(-10px); */
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
    box-shadow: 0 2px 15px 0.3 rgb(0, 0, 0, 0.1);
  }
  .active-dropdown {
    pointer-events: auto;
    opacity: 1;
    /* transform: translateY(0); */
  }
  .navContent-menu-child {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
  }
  .navContent-menu-child ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navContent-menu-child ul li a span {
    color: black;
    padding-bottom: 6px;
    width: 100%;
    border-bottom: 1px solid #111;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
  }
  .navContent-menu-child ul li a span:hover {
    color: #af9a7d;
    color: var(--primaryColor);
    font-weight: 800;
    border-bottom: 1px solid #af9a7d;
    border-bottom: 1px solid var(--primaryColor);
  }
  /* .service-icon-navbar-menu {
    font-size: 150%;
  } */

  .container-sociaux-links {
    visibility: visible;
  }
  .container-logo-title {
    display: block;
  }
  .container-nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 1281px) {
  .navContent-menu-child ul li a span {
    font-size: 11.5px;
  }
}
@media only screen and (max-width: 1025px) {
  /* .container-nav-links  */
  .nav-links li:nth-child(7) {
    display: none !important;
  }
  .navContent-menu-child ul li a span {
    font-size: 9px;
  }
}
/* end of the link style */

/* end of the navbar style */
/* start of the hero style */

/* .defaultHero {
  min-height: calc(100vh - 100px);
  background: url("./assets/hero1.jpg");
  background: url("./assets/video/background-video.mp4");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.defaultHero {
  min-height: calc(100vh - 100px);
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/static/media/hero1.8c44a344.jpg);
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/video/background-video.mp4"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* end of the hero style */
/* start of the banner style*/
.banner {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  color: var(--mainWhite);
  /* padding: 2rem 1rem; */
  /* margin:2rem 1rem; */
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  /* width: 100vw; */
}
.banner h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  z-index: 1000;
}
.banner div {
  width: 10rem;
  height: 8px;
  background: #af9a7d;
  background: var(--primaryColor);
  /* margin: 1.7rem auto; */
}
.banner p {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 8px #111;
  /* font-weight: 550; */
  text-align: center;
  line-height: 2;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
    text-shadow: 2px 2px 8px #111;
    line-height: 5rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}
/* end of the banner style */

/* start of the blog style */

.section {
  padding: 1rem 0;
  text-align: center;
  /* position: relative; */
  /* width: 100%; */
}
.global-disclosure-small-affiliate-section {
  width: 100%;
  min-height: 200px;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
.global-disclosure-small-affiliate-section-inner-container {
  width: 90%;
  margin: 0 auto;
}
.global-disclosure-small-affiliate-section-inner-container p {
  margin-right: 2px;
  display: inline-block !important;
  font-size: 15px !important;
}
.global-disclosure-small-affiliate-section-inner-container p strong {
  margin-right: 5px !important;
}

.section-center {
  width: 95vw;
  max-width: 1171px;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-row-gap: 3rem;
  grid-column-gap: 2rem;
  text-align: center;
  margin: 0 auto !important;
  /* overflow: hidden; */
}
.section-center-home-page {
  max-width: 1171px !important;
}

.global-container-position-blog {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 2rem auto 0;
}
.section__blogging__aside_author {
  width: 100%;
  min-height: 200px;
  margin-top: 2rem;
  border-radius: 4px;
  background: #fbfcfd !important;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px 10px rgb(72 139 201 / 20%);
}
.section__blogging__aside_author_container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3% 7% 0%;
}

.section__blogging__aside_author_container_author {
  width: 100px;
  height: 100px;
}
.section__blogging__aside_author_container_author img {
  width: 100%;
  height: 100%;
  border-radius: 100%;

  /* object-fit: contain; */
}
.section__blogging__aside_author_container_author_title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 15px;
  color: #111;
  font-weight: 700 !important;
  font-weight: 400;
  margin-left: 2%;
}
.section__blogging__aside_author p {
  color: #111 !important;

  text-justify: inter-word;
  text-align: left;
  width: 80%;
  margin: 1rem auto !important;
}
.section__blogging__aside_author h5 {
  color: #af9a7d;
  color: var(--primaryColor);
  font-size: inherit;
}
.section__blogging__aside_author a {
  color: #111;
  font-weight: 700;
}
.section__blogging__aside_author_socialLinks_position {
  display: flex;

  justify-content: center !important;
  align-items: center !important;
}
.article-link {
  text-decoration: none;
}

.blog-article {
  padding: 1rem 1rem 1rem;
  z-index: 999;
  border: 5px solid #af9a7d;
  border: 5px solid var(--primaryColor);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.blog-article:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.sommary-recap-article {
  text-align: start;
  width: 100%;
}
.sommary-recap-article ul {
  margin: 1.2rem 5%;
}
.sommary-recap-article ul li {
  color: black;
  list-style-type: square;
  color: #4799eb;
}
.sommary-recap-article ul li a {
  color: black;
  font-weight: 800;
  color: #4799eb;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  font-size: 14px !important;
}
.sommary-recap-article ul li a:hover {
  color: #af9a7d;
  color: var(--primaryColor);
}
.container-blog {
  min-height: 60vh;
  position: relative;
}

.container-subtitle h1 {
  font-size: 1.5rem;
  line-height: 1;
  height: 100%;
  font-weight: 800;
  color: #111;
  text-align: center;

  color: #0b1332;
  font-style: normal;
  font-family: "Qanelas Soft Bold" !important;
}
.container-subtitle h1:hover {
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  color: #af9a7d;
  color: var(--primaryColor);
}
.image-description {
  position: absolute;
  top: 25%;
  left: 0;
}

.container-image img {
  width: 100%;
  height: 12rem;
  object-fit: fill;
}
.description {
  font-size: 0.9rem;
  font-weight: 400;
  color: #111 !important;
  letter-spacing: 0.3px;
  line-height: 1.4;
  white-space: space normal;
  text-align: left;
}
.description:hover {
  color: red;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.description span {
  display: inline-block;
  color: blue;
  text-transform: capitalize;
  font-weight: 700;
}
.section__blogging__aside_author h5 {
  color: #af9a7d;
  color: var(--primaryColor);
  font-size: 1.2rem !important;
  text-align: center;
}
/* .global-section-position-email {
  width: 30% !important;
} */
.section-position-email {
  width: 30% !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.section-position-email input.filterEmail {
  width: 90% !important;
  height: 2.5rem !important;
}
/* .section-position-email
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
  width: 100vw !important;
  width: fit-content !important;
} */
.section-center {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media only screen and (max-width: 1281px) {
  .section-center {
    /* width: 70vw; */
    /* max-width: 700px; */
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 1rem;
  }
  /* .section-center {
    width: 98vw;
    max-width: 1200px;
    max-width: 750px;
  } */
  .global-container-position-blog {
    width: 95vw;
  }
  .section__blogging__aside_author {
    min-height: 250px;
  }
  .section__blogging__aside_author_container {
    padding: 3% 5% 0%;
  }

  .section__blogging__aside_author_container_author {
    width: 100px;
    height: 100px;
  }

  .section__blogging__aside_author_container_author_title {
    font-size: 20px;

    text-align: left;
    padding-left: 5%;
  }
  .section__blogging__aside_author p {
    color: #111 !important;
    line-height: 30px !important;
    font-size: 20px !important;

    text-align: left;
    width: 90%;
    margin: 5% auto !important;
  }
  .section__blogging__aside_author h5 {
    color: #af9a7d;
    color: var(--primaryColor);
    font-size: 1.5rem;
  }

  .blog-article {
    padding: 1rem;
    z-index: 999;
    border: 3px solid #af9a7d;
    border: 3px solid var(--primaryColor);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
  }
  .blog-article:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }

  .sommary-recap-article {
    width: 100%;
  }

  .container-subtitle h1 {
    font-size: 1.5rem;
    line-height: 1.2;
  }
  .container-subtitle h1:hover {
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
    color: #af9a7d;
    color: var(--primaryColor);
  }
  .image-description {
    position: absolute;
    top: 30%;
  }

  .container-image img {
    height: 13rem;
  }
  .description {
    font-size: 1.3rem !important;

    color: #111 !important;
    letter-spacing: 0.5px;
  }

  .description span {
    display: inline-block;
    color: blue;
    text-transform: capitalize;
    font-weight: 700;
  }
  .section-position-email {
    width: 30%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .section-position-email input.filterEmail {
    width: 90% !important;
    height: 2.5rem !important;
  }
  .section-position-email
    .full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
    max-width: 100vw !important;
  }
}

@media only screen and (max-width: 1025px) {
  .section-center {
    /* max-width: 95vw; */
    /* max-width: 700px; */
    /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 1rem; */
  }
  /* .global-container-position-blog {
    width: 95vw;
  } */
  .section__blogging__aside_author {
    min-height: 250px;
  }
  .section__blogging__aside_author_container {
    padding: 3% 5% 0%;
  }

  .section__blogging__aside_author_container_author {
    width: 100px;
    height: 100px;
  }

  .section__blogging__aside_author_container_author_title {
    font-size: 15px;
  }
  .section__blogging__aside_author p {
    color: #111 !important;
    line-height: 25px !important;
    font-size: 20px !important;

    text-align: left;
    width: 90%;
    margin: 5% auto !important;
  }
  .section__blogging__aside_author h5 {
    color: #af9a7d;
    color: var(--primaryColor);
    font-size: 1.2rem;
  }

  .blog-article {
    padding: 0.5rem;

    border: 2px solid #af9a7d;

    border: 2px solid var(--primaryColor);
  }
  .blog-article:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }

  .sommary-recap-article {
    width: 100%;
  }

  .container-subtitle h1 {
    font-size: 2rem;
    line-height: 1.3;
  }
  .container-subtitle h1:hover {
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
    color: #af9a7d;
    color: var(--primaryColor);
  }
  .image-description {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .container-image img {
    height: 15rem;
  }
  .description {
    font-size: 1.7rem !important;

    letter-spacing: 0.2px;
  }

  .description span {
    display: inline-block;
    color: blue;
    text-transform: capitalize;
    font-weight: 700;
  }
  .section-position-email {
    /* width: 28%;
    height: fit-content; */
    display: none;
  }
  /* .section-position-email input.filterEmail {
    width: 97% !important;
  } */
  /* .section-position-email
    .full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
    max-width: 90vw !important;
    display: none;
  } */

  /* .tutorial-card h4 {
    font-size: 2rem;
  } */

  .container-blog {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 1025px) and (min-height: 1366px) {
  .container-blog {
    min-height: 50vh;
  }
}

@media only screen and (max-width: 915px) {
  .section-center {
    width: 60vw;
    /* max-width: 1200px;
    margin: 0 auto !important;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 2rem; */
  }
  /* .global-container-position-blog {
    width: 100vw;
  } */
  /* .section__blogging__aside_author {
    display: none !important;
  } */
  .container-blogpost {
    /* width: 90%;
    height: 500px; */
    margin: 3rem auto !important;
  }

  .section-position-email {
    display: none !important;
  }

  .container-blog {
    min-height: 50vh;
  }
}
@media only screen and (max-width: 821px) {
  .section-center {
    width: 90vw;
  }
  .container-subtitle h1 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
  .container-blog {
    min-height: 65vh;
  }
}
@media only screen and (max-width: 768px) {
  .container-subtitle h1 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
  .image-description {
    position: absolute;
    top: 30%;
  }

  .container-image img {
    height: 18rem;
  }
  .description {
    font-size: 1.8rem !important;
  }
  .container-blog {
    min-height: 70vh;
  }
}

@media only screen and (max-width: 540px) {
  /* .container-blog {
    min-height: 55vh;
  } */
  .container-subtitle h1 {
    font-size: 2rem;
    line-height: 1.3;
  }
  .image-description {
    position: absolute;
    top: 30%;
  }

  .container-image img {
    height: 12rem;
  }
  .description {
    font-size: 1.2rem !important;
  }
  .container-blog {
    min-height: 80vh;
  }
}
@media only screen and (max-width: 431px) {
  .section-center {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    margin: 0 auto;
  }
  .container-subtitle h1 {
    font-size: 1.8rem !important;
    line-height: 1.2;
  }
  .container-blog {
    min-height: 60vh;
  }
}
@media only screen and (max-width: 395px) {
  .container-blog {
    min-height: 70vh;
  }
  .container-subtitle h1 {
    font-size: 1.5rem !important;
    line-height: 1.3;
  }
  .description {
    font-size: 1.5rem !important;
    line-height: 1.5 !important;
  }
}
@media only screen and (max-width: 376px) {
  .container-blog {
    min-height: 80vh;
  }
  .container-subtitle h1 {
    font-size: 1.3rem !important;
    line-height: 1.2 !important;
  }
  .description {
    font-size: 20px !important;
    line-height: 1.25 !important;
  }
}
@media only screen and (max-width: 361px) {
  .container-blog {
    min-height: 55vh;
  }
  .container-subtitle h1 {
    font-size: 1.5rem !important;
    line-height: 1.4;
  }
  .description {
    font-size: 16px !important;
    line-height: 1.25 !important;
  }
}
@media only screen and (max-width: 281px) {
  .section-center {
    width: 98vw;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    margin: 0 auto;
  }
  .container-blog {
    min-height: 72vh;
  }
  .container-subtitle h1 {
    font-size: 1.4rem !important;
    line-height: 1.2 !important;
  }
  .description {
    line-height: 1.5 !important;
  }
}
/* end of end blogs */
/* start of the tutorials style*/

.tutorial:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.blog-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.tutorial-image {
  border-top-left-radius: 0.25rem;
  border-top-left-radius: var(--radius);
  border-top-right-radius: 0.25rem;
  border-top-right-radius: var(--radius);
  /* height: 15rem; */
}
.tutorial-card {
  padding: 1.5rem 1rem;
}
.tutorial-card h4 {
  color: #111;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  font-weight: 600;
  text-transform: capitalize;
}
.tutorial-card h4:hover {
  color: #af9a7d;
  color: var(--primaryColor);
  font-weight: 800 !important;
}
.tutorial-card p {
  margin-top: 1rem;
  font-weight: 400;
  color: #111;
}
.tutorial-footer {
  text-align: left !important;
}

.tutorial-footer span {
  display: inline-block;
  background: hsl(212, 33%, 89%);
  background: var(--clr-grey-9);
  color: hsl(210, 22%, 49%);
  color: var(--clr-grey-5);
  margin-right: 0.5rem;
  margin-top: 10px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: #fff;
  letter-spacing: var(--mainWhite);
  font-size: 0.85rem;
}
.tutorial-footer span:nth-child(3) {
  margin-left: 0;
}
.tutorial-instructor-date {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.tutorial-instructor-date_instructor {
  width: 50px;
  height: 50px;
}

.tutorial-instructor-date_instructor img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: contain;
}
.tutorial-instructor-date span {
  color: hsl(210, 22%, 49%);
  color: var(--clr-grey-5);
  display: inline-block;
  font-size: 0.85rem;
  margin-left: 0.5rem;
}
/* .tutorial-article-background-image {
  background-image: url("https://res.cloudinary.com/ground-service-international/image/upload/v1647388359/javascript_6dd6011a77.png");
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;
  width: 100%;
  height: 100%;
} */

/* end of the tutorials style*/
/* start of the project style*/

/* start of the service style */
.service-card {
  padding: 1.5rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-align: center;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.section p {
  font-size: 19px;
  color: #d1dbe6;

  margin: 1rem 0;
  line-height: 1.6;
  font-family: "Qanelas Soft Light" !important;
  font-style: normal;
  font-weight: 400;
}
.section .introduction {
  margin-bottom: 1.5rem;
  width: 60%;
  margin: 0 auto;
  text-align: start;
}
/* .service-card:hover {
  background-color: #fff;
  transform: scale(1.02);
} */
.service-icon {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #af9a7d;
  color: var(--primaryColor);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
/* .service-card:hover .service-icon {
  color: blue;
  color: #fff;
} */
.service-card a {
  display: block;
  margin-top: 1rem;
  text-decoration: none;
  text-transform: capitalize;
}
/* .service-card:hover a:hover {
  color: var(--primaryColor);
} */
.service-card .underline {
  width: 3rem;
  height: 0.12rem;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  margin-bottom: 1.25rem;
  background: #2caeba;
  background: var(--clr-primary-5);
  background: #af9a7d;
  background: var(--primaryColor);
  margin-left: auto;
  margin-right: auto;
}
.service-card h4 {
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 700;
  color: #111;
  color: #fff;
}
.service-card p {
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  color: #d1dbe6;
  font-weight: 300;
  text-align: left;
}
@media screen and (max-width: 1000px) {
  .section .introduction {
    width: 90%;
    text-align: start;
  }
}

@media screen and (min-width: 676px) {
  .services-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .services-center {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* end of the service style */
.container-watch-video {
  position: relative;
  margin: 2rem auto;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
}
.container-watch-video,
.container-watch-video-background-inner {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 100%;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 0;
}
.container-watch-video-background-outer:hover {
  width: 100%;
  background: #af9a7d;
  background: var(--primaryColor);
  opacity: 1;
}

.container-watch-video-background-inner {
  opacity: 0;
  transition: width 0.4s cubic-bezier(0.34, 0.63, 0.29, 0.96);
}
.container-watch-video-background-inner:hover {
  opacity: 1;
}
.container-watch-video-background-inner .service-icon {
  background: none;
  color: black !important;
}
.container-watch-video-background-inner .service-icon,
.container-watch-video .service-icon {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.container-watch-video-background-outer {
  transition: width 0.4s cubic-bezier(0.34, 0.63, 0.29, 0.96);
  left: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0%;
  height: 100%;
  opacity: 0;
}

.img-window {
  /* visibility: hidden; */
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}

.btn-danger,
.authentification-btn-close,
.btn-danger-product,
.btn-slide-right {
  position: absolute;
  top: 0%;
  right: 5%;
  cursor: pointer;
  font-weight: 400;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #af9a7d;
  color: var(--primaryColor);
  opacity: 0.5;
}

.showVideo,
.showPopUp {
  visibility: visible;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.img-window .btn-danger:hover {
  opacity: 1;
}
.container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  overflow-x: hidden;
  background: transparent;
  border-radius: 4px;
  z-index: 99999;
  position: relative;
  padding-top: 56.25%;
  background: red;
}
.container-video .Youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-video-ceo-show {
  -webkit-transform: scale(1);
          transform: scale(1);
  /* transition: var(--mainTransition); */
}
.container-video-ceo-hide {
  -webkit-transform: scale(0);
          transform: scale(0);
  /* transition: var(--mainTransition); */
}

@media screen and (max-width: 768px) {
  .container-video {
    width: 390px !important;
    height: 225px !important;
  }
}
@media screen and (max-width: 378px) {
  .container-video {
    width: 350px !important;
  }
}
@media screen and (max-width: 320px) {
  .container-video {
    width: 300px !important;
  }
}

/* start of the goal style */
.goal-article {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

.goal-article h2 {
  color: #111;
  color: #fff;
  opacity: 0.8;
  text-align: left;
  margin-bottom: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.goal-article p {
  text-align: left;
  line-height: 1.5;
  color: #fff;
}
.goal-article img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 2rem;
}

@media screen and (min-width: 768px) {
  .goal-article {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }
  .goal-article img {
    margin-top: 0;
    height: auto;
  }
}

/* end of the goal style */

/* start of the responsive style */
.responsive-article {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}
.responsive-article img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 2rem;
}
.mobile-version {
  display: none !important;
}
.responsive-article h2 {
  color: #111;
  color: #fff;
  opacity: 0.8;
  text-align: left;
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.responsive-article ul {
  list-style-type: none;
}
.responsive-article ul li {
  color: #111;
  color: #fff;
  text-align: left;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .responsive-article {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .mobile-version {
    display: block !important;
  }
  .responsive-article img {
    margin-top: 0;
  }
}

/* end of the responsive style */
/* start of the testimonials style */
.container-affiliates-reviews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100vw;
  max-width: 1171px;
  margin: 0 auto;
}
@media screen and (max-width: 300px) {
  .container-affiliates-reviews {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 90vw;
  }
}

.section-center-home {
  width: 90vw;
  grid-template-columns: repeat(12, 367px);
  margin-top: 2rem;
  grid-column-gap: 32px;
  transition: -webkit-transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s, -webkit-transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.container-testimonial {
  background: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1);
}
@media screen and (max-width: 300px) {
  .section-center-home {
    grid-template-columns: repeat(12, 270px);
  }
  .container-testimonial {
    width: 100%;
  }
  .testimonial-article {
    width: 270px;
  }
}

.testimonial-name-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.container-title-company-description {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0.1rem auto;
}
.testimonial-name-image img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--lightShadow);
}
.testimonial-name-image h4 {
  text-transform: capitalize !important;
  margin-left: 0.6rem;

  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  text-transform: none;
  font-weight: 600;
  color: #02203c;
  text-transform: capitalize;
  font-size: 18px;
}
.container-title-company-description h4 {
  margin: 0.2rem 0;
  text-transform: capitalize;
  font-size: 18px;
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  text-transform: none;
  font-weight: 400;
  color: #02203c;
}
.container-title-company-description h4:nth-child(1) {
  padding-top: 0.8rem;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.container-company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0;
}
.company-logo {
  width: 72px;
  height: 30px;
}
.container-slide-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  margin-top: 1rem;
  margin: 1rem auto;
  color: #af9a7d;
  color: var(--primaryColor);
}
.container-slide {
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 4px 1px rgba(50, 50, 93, 0.1);
  padding: 0.6rem;
  border-radius: 100%;
  cursor: pointer;
  transition: box-shadow 0.2s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.2s;
  transition: transform 0.3s, box-shadow 0.2s, -webkit-transform 0.3s;
}
.container-slide:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
/* end of the testimonials style */

/* start of the companies style*/
.section-companies {
  background: #fff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  max-width: 1171px;
  /* max-width: 100vw; */
  overflow: hidden;
  z-index: 1;
  margin: 0 auto;
  padding: 2rem 0;
}
.companies-article {
  position: relative;
}
.companies-article .companies-container-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: 100%;
  z-index: 1;
}
.companies-article .companies-container-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article .companies-container-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article-container {
  width: 1614px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.companies-container {
  position: relative;
  height: 430px;

  flex: 0, 1 250px;
}
.container-logo-company {
  display: grid;
  grid-template-columns: repeat(7, 200px);
  grid-column-gap: 32px;
  margin: 0 auto;
  margin-top: 2rem;
  position: absolute;
}
@-webkit-keyframes slide1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes slide1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.container-logo1-companies {
  -webkit-animation: slide1 20s linear 0s infinite;
          animation: slide1 20s linear 0s infinite;
  top: 0;
}
@-webkit-keyframes slide2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
@keyframes slide2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.container-logo2-companies {
  top: 0;
  -webkit-animation: slide2 20s linear 0s infinite;
          animation: slide2 20s linear 0s infinite;
}
@-webkit-keyframes slide3 {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
@keyframes slide3 {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
.container-logo3-companies {
  bottom: 0;
  -webkit-animation: slide3 20s linear 0s infinite;
          animation: slide3 20s linear 0s infinite;
}
@-webkit-keyframes slide4 {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes slide4 {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
.container-logo4-companies {
  bottom: 0;
  -webkit-animation: slide4 20s linear 0s infinite;
          animation: slide4 20s linear 0s infinite;
}
/* .companies-article .companies-container-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: 100%;
  z-index: 1;
}
.companies-article .companies-container-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.companies-article .companies-container-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
} */
.companies-container div {
  width: 200px;
  height: 150px;
}
.companies-container div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  opacity: 1;
}
.opacity-companies {
  opacity: 1 !important ;
  -webkit-filter: grayscale(100%) brightness(100%) !important;
  filter: grayscale(100%) brightness(59%) !important;
}
/* end of the companies style */

/* start of the affiliate style */
/* .container-description {
  max-width: 800px;
  width: 90%;
  margin: 2rem auto;
  margin-bottom: 0;
  padding: 0;
}
.container-description p {
  text-align: left;

  font-size: 1rem;
  color: #111;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1.4px;
  margin-bottom: 1.5rem;
}
.container-description p span {
  font-weight: 600;
  font-size: 15px;
} */
/* end of the affiliate style */

/* start of the footer style */

.footer {
  min-height: 100vh;
  background: #000000;
  background: rgba(0, 0, 0, 0.5);
}
.container-new-letter {
  /*  width: 100%; */
  margin: 2rem 0;
}
.container-new-letter p:nth-child(1) {
  font-size: 2rem;
  color: #fff;
}
.container-new-letter p:nth-child(2) {
  font-size: 1.5rem;
  color: #fff;
}
.new-letters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.new-letters input {
  width: 30%;
  height: 60px;
  border: none;
  padding-left: 5%;
  outline: none;
}
.new-letters input::-webkit-input-placeholder {
  text-align: 6%;
}
.new-letters input:-ms-input-placeholder {
  text-align: 6%;
}
.new-letters input::placeholder {
  text-align: 6%;
}
.new-letters button {
  width: 5%;
  height: 60px;
  cursor: pointer;
  background: #fff;
  color: black;
  border: none;
  font-weight: bold;
  margin-left: 0.2%;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.new-letters button:hover {
  background: #af9a7d;
  background: var(--primaryColor);
  color: white;
}
.container-new-letter .successReview {
  margin-bottom: 1rem;
  font-size: 1.2rem !important;
}
.container-new-letter .errorReview {
  margin-top: 1rem;
  font-size: 1.2rem !important;
}

@media screen and (max-width: 761px) {
  .new-letters {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .new-letters input {
    width: 75%;
    border: none;
    outline: none;
  }
  .new-letters button {
    width: 20%;
    font-size: 14px;
  }
  .new-letters input::-webkit-input-placeholder {
    text-align: 0;
    font-size: 14px;
  }
  .new-letters input:-ms-input-placeholder {
    text-align: 0;
    font-size: 14px;
  }
  .new-letters input::placeholder {
    text-align: 0;
    font-size: 14px;
  }
}

.footer-product-links p a {
  color: #fff;
  /* font-size: 1rem !important; */
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  font-weight: 600;
  font-size: 15px;
}
.footer-product-links p a:hover {
  color: #af9a7d;
  color: var(--primaryColor);
}

.container-footer {
  display: grid;
  grid-template-columns: 1fr;
  width: 90vw;
  text-align: left;
  margin: 0 auto;
}
.container-footer div h4 {
  color: #af9a7d;
  color: var(--primaryColor);
  text-align: left;
  margin: 1em 0;
  font-size: 1.2rem;
  text-transform: capitalize;
}
/* .container-footer div ul {

} */
.container-footer div ul li {
  list-style: none;
  line-height: 1.7;
  font-weight: 600;
}
.container-footer div ul li a {
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
}
address {
  display: block;
  font-size: 15px;
}
.office-address {
  font-size: 15px;
  color: #af9a7d;
  color: var(--primaryColor);
  font-weight: 600;
}
.email-address {
  font-size: 15px;
  font-weight: italic;
  color: white;
  text-transform: none;
  font-weight: 600;
}

footer {
  position: relative;
}
.copyright {
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 15px;
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  /* width: 100vw; */
}
.copyright span {
  color: #af9a7d;
  color: var(--primaryColor);
}

@media screen and (min-width: 700px) {
  .container-footer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(-webkit-fit-content, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(fit-content, 1fr));
    margin: 0 auto;
    width: 90vw;
  }
  .container-footer div h4 {
    font-size: 1.3rem;
  }
  .container-footer div ul li a {
    font-size: 15px;
  }
  .footer-product-links p a {
    font-size: 15px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1000px) {
  .container-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 95vw;
  }
}
/* 
/* end of the footer style */
/* start of the slider style */
.slider {
  position: fixed;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  left: 0;
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  z-index: 99;
  background-color: #111;
  padding-top: 5%;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  margin: 0 auto;
}
.showSlider {
  opacity: 0.97;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.btn-slide-right {
  color: #fff;
  padding-right: 1.5rem;
}
.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 4%;
  width: 100vw;
}
.container-all-content {
  margin: 0% 0 1%;
  width: 95vw;
  height: 100vh;
}
.container-all-content ul {
  width: 100vw;
  height: 100vh;
}
.container-all-content li {
  list-style: none;
}
.container-all-content li a {
  display: grid;
  align-items: center;
  margin-top: 5%;
  grid-template-columns: auto 1fr;
}
.container-all-content li a span {
  width: 100%;
  margin-left: 20%;
  font-size: 1rem;
  color: #af9a7d;
  color: var(--primaryColor);
}
@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.container-all-content li {
  opacity: 0;
  -webkit-animation: slideRight 0.5s ease-in-out 0.3s forwards;
          animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.container-all-content li:nth-of-type(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.container-all-content li:nth-of-type(2) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.container-all-content li:nth-of-type(3) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.container-all-content li:nth-of-type(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.container-all-content li:nth-of-type(5) {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}
.container-all-content li:nth-of-type(6) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}
.container-all-content li:nth-of-type(7) {
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s;
}
.container-all-content li:nth-of-type(8) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.container-all-content li:nth-of-type(9) {
  -webkit-animation-delay: 2.25s;
          animation-delay: 2.25s;
}
.container-all-content li:nth-of-type(10) {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}
.container-all-content li:nth-of-type(11) {
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.active-btn {
  border-left: 7px solid #af9a7d;
  border-left: 7px solid var(--primaryColor);
}
.slider-container .nav-header .container-logo .container-logo-symbol span {
  font-size: 1.2rem;
}
.slider-container .nav-header .container-logo .container-logo-title span {
  font-size: 1.2rem;
}
@media screen and (min-width: 279px) {
  .container-all-content li {
    margin-top: 7%;
  }
  .container-all-content li .nav-icon-logo {
    font-size: 1.5rem;
  }
  .container-all-content li a span {
    margin-left: 10%;
    font-size: 0.8rem;
  }
  .slider-container .nav-header .container-logo .container-logo-title {
    display: block;
  }
}
@media screen and (min-width: 378px) {
  .container-all-content li {
    list-style: none;
    margin-top: 5%;
  }
  .container-all-content li .nav-icon-logo {
    font-size: 2.5rem;
  }
  .container-all-content li a span {
    margin-left: 15%;
    font-size: 1.2rem;
  }
  .slider-container .nav-header .container-logo .container-logo-symbol span {
    font-size: 1.5rem;
  }
  .slider-container .nav-header .container-logo .container-logo-title span {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 540px) {
  .container-all-content li {
    list-style: none;
    margin-top: 3%;
  }
  .container-all-content li .nav-icon-logo {
    font-size: 1.5rem;
  }
  .container-all-content li a span {
    margin-left: 15%;
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .container-all-content li {
    margin-top: 3%;
  }
  .container-all-content li .nav-icon-logo {
    font-size: 2.6rem;
  }
  .container-all-content li a span {
    margin-left: 20%;
    font-size: 1.5rem;
  }
  .slider-container .nav-header .container-logo .container-logo-symbol span {
    font-size: 2rem;
  }
  .slider-container .nav-header .container-logo .container-logo-title span {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1300px) {
  .slider {
    display: none;
  }
}

/** start single container */
.all-container-blogpost {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-right: 5% !important;
  text-align: left !important;
}
.all-container-blogpost-update-blog-author {
  width: 50%;

  /* width: 90%; */
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-container-blogpost-update-blog-author span:nth-child(3) {
  color: #af9a7d;
  color: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #af9a7d;
  background: var(--primaryColor);
}
.all-container-blogpost-update-blog-author-profile {
  width: 50px;
  height: 50px;
  display: inline-block;
}
.all-container-blogpost-update-blog-author-profile img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: contain;
}
.all-container-blogpost-update-blog-author-date-mobile {
  display: none !important;
  text-align: left;
}
.section-position-email {
  width: 35% !important;
  position: sticky !important;
  -webkit-position: -webkit-sticky;
  top: -190px;
  top: 90px;
  left: 0;
}
.display {
  display: none;
}
.desktop-upvotes-comments {
  display: none;
}
.mobile-upvotes-comments {
  display: none !important;
}
.section-position-email-table-of-content {
  background: #fff;
  width: 100%;
  min-height: 200px;
  /* margin-top: 2rem; */
  border-radius: 4px;
  padding: 2rem 0;
  padding-left: 5%;
}
.section-position-email-table-of-content p {
  font-size: 18px;
  color: #111;
  font-weight: 400;
  text-align: left;
}
.section-position-email-table-of-content nav {
  background: #fff;
  width: 90%;
  margin: 0 auto;
}
.section-position-email-table-of-content h4 {
  color: #111;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}
.section-position-email-table-of-content nav ul {
  width: 100% !important;
  margin-top: 1rem;
  min-height: 100%;
  line-height: 1.8;
  text-align: left;
}
.section-position-email-table-of-content nav ul li :hover {
  color: #4799eb !important;
}

.section-position-email-table-of-content nav ul li a {
  color: #111;
  font-size: 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  transition: all 0.2s ease-in-out;
}
.section-position-email-table-of-content nav ul li a:hover {
  color: #4799eb !important;
}

.navigation-activeClass {
  color: #4799eb !important;
  transition: 0.3s ease-in-out all;
  font-weight: 800;
}
.all-container-blogpost {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}
.section-title h1 {
  font-size: 1.3rem !important;
  width: 100% !important;
  font-weight: 600;
  text-align: center;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  line-height: 2;
  color: #fff;
}
.read-all-blog {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
}
.read-all-blog:hover {
  opacity: 1;
}
.read-all-blog span {
  color: #af9a7d;
  color: var(--primaryColor);
  color: #4799eb;
  font-size: 700;
  font-size: 16px;
}
.icon-open-blog {
  color: #4799eb !important;
  font-size: 1.5rem;
}
.show-blog {
  display: block;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.hide-blog {
  display: none;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-blogpost {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 2rem auto;
}
.container-blogpost img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blockquote {
  display: block;
  margin: 5% 5% 3%;
  color: #f69314;
  color: #4799eb !important;
  text-align: center !important;
}
.author-quote {
  font-size: 15px;
  font-weight: bold;
  color: #af9a7d;
  color: var(--primaryColor);
  margin-bottom: 5%;
  font-weight: 600 !important;
  text-align: center !important;
}
.description-paragraph {
  font-size: 20px !important;
  line-height: 1.5 !important ;
  width: 100%;
  text-align: start;
  margin-top: 1.5rem !important;
}
.description-paragraph a {
  color: #af9a7d;
  color: var(--primaryColor);
  /* font-size: 18px; */
}
.subtitle-description {
  font-size: 1.3rem;
  margin: 5% 0;
  text-transform: capitalize;
}
.suggestion-users {
  background-color: #af9a7d;
  background-color: var(--primaryColor);
  margin: 5% 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 4%;
}

.suggestion-users p {
  text-align: left;
  font-size: 15px;
  color: black;
  font-weight: 700;
  text-transform: capitalize;
}
.suggestion-blog {
  display: grid;
  grid-template-columns: 2fr auto;
  padding: 1rem;
  /* border: 5px solid var(--primaryColor) !important; */
  grid-row-gap: 1.5rem;
  grid-column-gap: 1rem;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
  -webkit-transform: scale(1);
          transform: scale(1);
}
.suggestion-blog:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.suggestion-blog p {
  text-align: left;
  color: red;
  font-weight: 700;
  margin-right: 1.5%;
  color: black;
}
.suggestion-blog img {
  width: 120px;
  height: 120px;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.all-suggestion-container {
  background: #fff;
  padding: 1rem 0;
}
.all-suggestion-container-related-border {
  color: black !important;
  text-align: center !important;
  font-weight: 800 !important;
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.all-suggestion-container-related-border p {
  width: 50px !important;
  height: 2px !important;
  background: black !important;
}
.all-suggestion-container-related-definition {
  color: black !important;
  text-align: center;
  font-weight: 800;
  font-size: 1.6rem;
}
.suggestion-container {
  max-width: 90vw;
  width: 1171;
  margin: 2rem auto 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1rem;
}
@media only screen and (max-width: 1281px) {
  .all-container-blogpost {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-right: 0% !important;
    text-align: left !important;
  }
  .desktop-upvotes-comments {
    display: none !important;
  }

  .mobile-upvotes-comments {
    display: flex !important;
    margin: 0 auto;
    width: 80%;
  }
  .all-container-blogpost-update-blog-author {
    width: 60%;
  }
}
@media only screen and (max-width: 1025px) {
  .all-container-blogpost-update-blog-author {
    width: 80%;
  }
}
@media only screen and (max-width: 917px) {
  .desktop-upvotes-comments {
    display: none !important;
  }

  .mobile-upvotes-comments {
    display: flex !important;
    margin: 0 auto;
    width: 80%;
  }
  .all-container-blogpost {
    width: 95% !important;

    text-align: center !important;
    margin: 0 auto !important;
  }

  .section-title h1 {
    font-size: 2rem !important;
    line-height: 1.8;
    margin-bottom: 1rem;
  }

  .container-blogpost {
    margin: 2rem auto;
  }

  .blockquote {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .author-quote {
    font-size: 1.4rem !important;
  }
  .description-paragraph {
    font-size: 1.5rem !important;
    line-height: 1.5 !important ;
    width: 100%;
    text-align: start;
    margin-top: 1.5rem !important;
  }
  .subtitle-description {
    font-size: 2.2rem !important;
    margin: 5% 0;
    text-transform: capitalize;
  }
  .all-container-blogpost-update-blog-author-profile {
    width: 120px;
    height: 120px;
  }
  .global-disclosure-small-affiliate-section {
    padding: 1rem 0;
  }
  .global-disclosure-small-affiliate-section-inner-container p {
    font-size: 20px !important;
    line-height: 2;
  }
}

@media only screen and (max-width: 821px) {
  .description-paragraph {
    font-size: 1.3rem !important;
    line-height: 1.5 !important ;
  }
  .subtitle-description {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 769px) {
  .description-paragraph {
    font-size: 1.2rem !important;
  }
  .subtitle-description {
    font-size: 1.8rem !important;
    margin: 5% 0;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 541px) {
  .section-title h1 {
    font-size: 1.2rem !important;
  }
  .description-paragraph {
    font-size: 1rem !important;
  }
  .subtitle-description {
    font-size: 1.5rem !important;
    margin: 5% 0;
    text-transform: capitalize;
  }
  .all-container-blogpost-update-blog-author {
    width: 100%;
  }
  .all-container-blogpost-update-blog-author {
    width: 50%;
  }
  .all-container-blogpost-update-blog-author-profile {
    width: 70px;
    height: 70px;
  }
  .all-container-blogpost-update-blog-author {
    width: 80%;
  }
}
@media only screen and (max-width: 415px) {
  .section-title h1 {
    font-size: 1.3rem !important;
  }
  .all-container-blogpost-update-blog-author {
    width: 50%;
  }
  .all-container-blogpost-update-blog-author-profile {
    width: 100px !important;
    height: 100px !important;
  }
  .description-paragraph {
    font-size: 1.1rem !important;
    line-height: 2 !important;
  }
  .subtitle-description {
    font-size: 1.5rem !important;
    margin: 8% 0;
  }
  .all-container-blogpost-update-blog-author-name {
    font-size: 20px;
  }
  .all-container-blogpost-update-blog-author-separator {
    display: none !important;
  }
  .all-container-blogpost-update-blog-author-date {
    display: none !important;
  }
  .all-container-blogpost-update-blog-author-date-mobile {
    display: block !important;
    font-size: 20px !important;
    margin-top: 1.2rem;
  }
  .all-container-blogpost-update-blog-author-date-mobile {
    display: block !important;
  }
}
@media only screen and (max-width: 391px) {
  .suggestion-container {
    width: 95vw;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important ;
    margin: 2rem auto;
  }
  .section-title h1 {
    font-size: 1rem !important;
  }
  /* .all-container-blogpost-update-blog-author {
    width: 100% !important;
  } */
  .description-paragraph {
    font-size: 1rem !important;
    line-height: 2 !important;
  }
  .subtitle-description {
    font-size: 1.2rem !important;
    line-height: 1.5;
  }
  .all-container-blogpost-update-blog-author-profile {
    width: 70px !important;
    height: 70px !important;
  }

  .global-disclosure-small-affiliate-section {
    padding: 0.5rem 0;
  }
  .global-disclosure-small-affiliate-section-inner-container p {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 376px) {
  .suggestion-container {
    width: 95vw;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important ;
    margin: 2rem auto;
  }
  .section-title h1 {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 281px) {
  .all-container-blogpost-update-blog-author {
    width: 100% !important;
  }
  .all-suggestion-container {
    padding-top: 0.5rem 0;
  }
  .suggestion-container {
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr)) !important;
  }
  .suggestion-blog {
    width: 95vw;
  }
  .suggestion-blog p {
    font-size: 12px !important;
  }
  .suggestion-blog img {
    width: 80px;
    height: 80px;
  }
  .all-container-blogpost-update-blog-author-profile img {
    border-radius: 50%;
    object-fit: cover;
  }
  .section-title h1 {
    font-size: 0.9rem !important;
  }
  .description-paragraph {
    font-size: 0.8rem !important;
    line-height: 2 !important;
  }
  .subtitle-description {
    font-size: 0.9rem !important;
  }
  .all-container-blogpost-update-blog-author-profile {
    width: 50px;
    height: 50px;
  }

  .global-disclosure-small-affiliate-section {
    padding: 0.5rem 0;
  }
  .global-disclosure-small-affiliate-section-inner-container p {
    font-size: 10px !important;
  }
}

/** end single container blog post */

/* @media only screen and (min-width: 900px) {
  .all-container-blogpost {
    width: 50%;
  }
  .section-title h1 {
    width: 50%;
  }
  .read-all-blog span {
    color: var(--primaryColor);
    color: #4799eb;
    font-size: 700;
  }
  .icon-open-blog {
    color: black;
    font-size: 1.5rem;
  }
  .show-blog {
    display: block;
    transition: var(--mainTransition);
  }

  .container-blogpost {
    width: 90%;
    height: 500px;
    margin: 3rem 0;
  }
  .suggestion-users {
    width: 40%;
  }
  .suggestion-users p {
    font-size: 18px;
  }
  .suggestion-blog {
    border-bottom: none;
  }

  .suggestion-blog p {
    text-align: left;
    font-weight: 700;
    margin-right: 0;
    color: black;
  }
  .suggestion-blog img {
    width: 150px;
    height: 150px;
    border-radius: var(--radius);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  }
} */
/* end of the style blogpost style */
/* start of the ceo container */

.ceo-container {
  margin: 2rem;
}
.allcontainerfounder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
.all-ceo-description {
  background-color: #f6f9fc;
}
.ceo-picture-sociaux {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ceo-picture {
  margin: 1rem;
}
.ceo-container h2 {
  font-size: 35px;
  text-transform: capitalize;
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  color: #02203c;
  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
  width: 100%;
}
.ceo-container h4 {
  color: #445d6e;
  margin: 1rem 0;
  font-weight: 400;
  font-size: 18px;
  width: 100%;
}
.ceo-container-picture {
  /* margin-top: 1rem; */
  width: 250px;
  height: 250px;
}
.ceo-container-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.3);
}
.ceo-background {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ceo-background {
  text-align: justify;
}
.ceo-background p {
  line-height: 1.5;
  color: #445d6e;
  font-size: 16px;
}
.ceo-background p {
  line-height: 1.8;
  font-size: 17px;
}

@media only screen and (min-width: 900px) {
  .allcontainerfounder {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
  }
  .ceo-container h4 {
    font-size: 1.3rem;
  }
  .ceo-container-picture {
    /* margin: 2.5rem 0; */
    width: 300px;
    height: 300px;
  }

  .ceo-background p {
    line-height: 1.8;
    font-size: 18px;
    text-align: match-parent !important;
  }
}
/*end of the ceo container */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 90vw;
  margin: 0 auto 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
  color: black;
}
.form-group label {
  display: block;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: transparent;
}
.form-group input {
  background: transparent;
  border: 1px solid black;
  width: 90% !important;
  height: 2.5rem;
  text-indent: 5%;
  font-size: 1.5;
  color: black;
  font-weight: 600;
}
.form-group button {
  margin: 0;
  /* width: 10% !important; */
  height: 2.5rem;
  border: 1px solid black;
  color: black;
}
::-webkit-input-placeholder {
  font-size: 1.1rem;
  color: #af9a7d;
  color: var(--primaryColor);
  color: black;
  font-weight: 600;
  opacity: 1;
  text-transform: capitalize;
}
:-ms-input-placeholder {
  font-size: 1.1rem;
  color: #af9a7d;
  color: var(--primaryColor);
  color: black;
  font-weight: 600;
  opacity: 1;
  text-transform: capitalize;
}
::placeholder {
  font-size: 1.1rem;
  color: #af9a7d;
  color: var(--primaryColor);
  color: black;
  font-weight: 600;
  opacity: 1;
  text-transform: capitalize;
}

.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid #222;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
  .form-control {
    width: 100%;
  }
  .form-group input {
    width: 45% !important;
  }
  /* .form-group button {
    width: 5% !important;
  } */
}

.container-contents-videocourse__display__video {
  position: relative;
}
.contentFixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0px;
  z-index: 1099;
  overflow: scroll;
  width: 100%;
  background-color: #f7f8fc;
  min-height: 100vh;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.contentFixedHide {
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
}
.contentFixedShow {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

/* end of rooms fitler*/
@media screen and (min-width: 992px) {
  .contentFixed {
    transition: none;
  }
  .container-contents-videocourse__display__video {
    display: grid;
    width: 100%;
    justify-content: flex-end;
    grid-template-columns: 80%;
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
  }
  .container-contents-videocourse__display__video__show {
    display: grid;
    width: 100%;
    justify-content: flex-start;
    grid-template-columns: 100%;
    transition: all 0.5s ease-in-out !important;
    transition: var(--mainTransition) !important;
  }
  .contentFixed {
    width: 20%;
  }
  /* .contentFixedHide {
  transform: translateX(-191%);
}
.contentFixedShow {
  transform: translateX(0%);
} */
}
/* start of the single  project section */

/* end of the single  project section */

/* start of the navbar projects */
.section-project {
  background-color: #fff;
  margin-bottom: 2rem;
}
.all-projects-container {
  display: none;
}
.global-container-projects {
  position: relative;
  padding-top: 110px;
}

.container-single-project__navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #0af;
  background: var(--secondPrimaryColor);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  z-index: 99;
}
.container-single-project__navBar__description {
  width: 90%;
  min-height: 70px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-single-project__navBar__description .icon-description-desktop {
  font-size: 2.5rem;
  background: transparent;
  display: none;
}
.container-single-project__navBar__description .icon-description-mobile {
  font-size: 2.5rem;
  background: transparent;
}
/* .container-single-project__course__title {
  padding-top: 100px;
} */

.container-single-project__navBar__description h4 {
  font-size: 15px;
}
.container-single-project__navBar__description a {
  border: 4px solid #af9a7d;
  border: 4px solid var(--primaryColor);
  padding: 9px;
  font-size: 18px;
  text-transform: capitalize;
  color: white;
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.container-single-project__navBar h4:nth-child(3) {
  display: none;
}
@media screen and (min-width: 992px) {
  .container-single-project__navBar__description .icon-description-desktop {
    display: block;
    cursor: pointer;
  }
  .container-single-project__navBar__description .icon-description-mobile {
    display: none;
  }
  .container-single-project__navBar__description {
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 70px;
  }
  .container-single-project__navBar h4:nth-child(3) {
    display: block;
    color: white;
    text-transform: capitalize;
    font-size: 1.1rem;
  }
}
/* end of the navbar projects */

/* start of the section of the video course project */
.container-single-project__course__title div span,
h6 {
  font-size: 1.3rem;
  color: black;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
}

.container-single-project__course__by_order {
  min-width: 80%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-single-project__course__by_order span {
  font-size: 12px;
  color: black;
  color: darkgray;
  text-transform: capitalize;
}
.video-container-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  position: relative;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  padding-top: 56.25%;
  z-index: 0 !important;
}
.video-container-projects .React-player {
  position: absolute;
  top: 0;
  z-index: 0 !important;
  width: 99% !important;
  height: 99% !important;
}

@media screen and (min-width: 992px) {
  .container-single-project__course__by_order {
    min-width: 25%;
    width: 27%;
  }
  .container-single-project__course__by_order span {
    font-size: 15px;
  }
  .video-container-projects .React-player {
    position: absolute;
    top: 0;
    z-index: 0 !important;
    width: 95% !important;
    height: 95% !important;
  }
}
@media screen and (min-width: 1400px) {
  .video-container-projects .React-player {
    width: 75% !important;
    height: 75% !important;
  }
}
/* @media screen and (min-width: 1500px) {
  .video-container-projects .React-player {
    width: 73% !important;
    height: 73% !important;
  }
} */
/* end of the section of the video course project */

/* start of the code */
.source-code {
  font-size: 17px;
  margin: 1.5rem 0;
  width: 90%;
  margin: 0 auto;
}
.source-code a {
  display: block;
  color: black;
  color: #0af;
  color: var(--secondPrimaryColor);
  text-align: left !important;
  margin: 1.3rem 2.5%;

  text-transform: capitalize;
  font-weight: 700;
  line-height: 2;
}

@media screen and (min-width: 992px) {
  .source-code {
    width: 60%;
    font-size: 17px;
    margin: 3rem auto;
  }
  .source-code a {
    text-align: left !important;
    margin-left: 5%;
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
  }
  .source-code a:hover {
    text-decoration: underline;
  }
}
/* end of the code */
/* start of the container description video */
.container-description-video {
  width: 90%;
  margin: 2rem auto 0;
}
.container-description-video h5 {
  text-transform: capitalize;
  color: black;
  text-align: left;
  margin-left: 2.5%;
  font-size: 1.2rem;
}
.container-description-video p {
  text-align: start;
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
  line-height: 1.7;
  color: #5e6971;
}
@media screen and (min-width: 992px) {
  .container-description-video {
    width: 60%;
    margin-top: 2rem;
  }
  .container-description-video h5 {
    margin-left: 0;
    padding-left: 5%;
  }
  .container-description-video p {
    width: 100%;
    padding-left: 5%;
  }
}
/* start of the pagination */
.pagination-desktop {
  display: none;
}
.pagination-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.pagination-mobile {
  width: 47%;
  background: #f7f8fc;
  padding: 1.5rem;
  font-weight: 700;
}
.pagination-mobile a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

@media screen and (min-width: 1400px) {
  .pagination-desktop {
    display: block;
    width: 100%;
  }
  .pagination-mobile {
    display: none;
  }
  .pagination-desktop div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #f7f8fc;
    padding: 1.5rem;
    font-weight: 700;
    transition: all 0.5s ease-in-out;
    transition: var(--mainTransition);
    cursor: pointer;
  }
  .pagination-desktop div:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  .pagination-desktop div span,
  .pagination-desktop__arrow {
    display: block;
    margin-left: 5px;
    color: #36434d;
  }
  .pagination-desktop div span {
    display: block;
    margin-left: 5px;
    color: #36434d;
  }
  .pagination-container {
    width: 50%;
  }
}
/* end of the pagination */
/* start of the user comments */
.container-comments-users-count {
  width: 90%;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
}
.container-commments-users {
  width: 100%;
}

.comments-user-count {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: right;
  margin: 0 auto;
}
.comments-user-count p {
  padding-left: 2%;
}
.comments-user-count div {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
}
.comments-user-count div p {
  font-size: 14px;
  font-weight: 700;
}
.comments-user-count .icon {
  font-size: 1.5rem;
  color: #af9a7d;
  color: var(--primaryColor);
}
.singular-comment {
  display: none;
}
.container-users {
  max-width: 100%;
  width: 100%;
  margin: 1.3rem auto 0;
  display: grid;
  grid-template-columns: 15% 80%;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%;
  padding-bottom: 3rem;
}
.container-users img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.submit-users-comment {
  max-width: 100%;
}
.container-users textarea {
  max-width: 100%;
  width: 20rem;
  height: 120px;
  resize: none;
  font-size: 15px;
  padding: 1% 2% 0;
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 0.25rem;
  border-radius: var(--radius);

  background-color: #f7f8fc;
}

.container-users textarea::-webkit-input-placeholder {
  color: #36434d;
  font-size: 20px;
  text-transform: none;
  background-image: none;
  border: 1px solid #e0e7ed;
}

.container-users textarea:-ms-input-placeholder {
  color: #36434d;
  font-size: 20px;
  text-transform: none;
  background-image: none;
  border: 1px solid #e0e7ed;
}

.container-users textarea::placeholder {
  color: #36434d;
  font-size: 20px;
  text-transform: none;
  background-image: none;
  border: 1px solid #e0e7ed;
}
.submit-users-comment button {
  width: 90%;
  padding: 1rem 0;
}
.container-users span {
  color: black;
}
.container-users-comments-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: center;
  border-top: 1px solid #e0e7ed;
  /* padding: 2rem 6% 0; */
  padding: 2rem 5%;
}
.container-users-comments-description span {
  color: black;
}
.container-users-comments-description div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-users-comments-description div span {
  padding-left: 1rem;
  color: #111;
}
.container-users-comments-description img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.container-users-comments-description__user {
  text-transform: capitalize;
}
.container-users-comments-description__comments__duration {
  text-align: right;
}
.like-comments {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 2%;
}

.like-comments p {
  word-break: break-word;
  display: flex;
  color: black;
  text-align: left;
}
/* .like-comments div span {
  color: black !important ;
}
.like-button {
  color: black !important;
} */
.container-like-comments-reply {
  border-left: 2px solid rgb(133, 153, 169);
  margin-left: 11%;
  padding-bottom: 1rem;
  color: black;
}
.container-like-comments {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.container-like-comments span {
  padding-left: 5%;
}

.empty-div-bottom {
  width: 100%;
  height: 70px;
  background: white;

  margin-top: 3rem;
}

@media screen and (min-width: 992px) {
  .container-comments-users-count {
    width: 35%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);

    margin: 5rem auto;
  }
  .container-commments-users {
    width: 100%;
  }

  .comments-user-count {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }
  .comments-user-count p {
    font-size: 18px;
    font-weight: 600;
    padding-left: 1%;
    margin-top: 1rem;
  }
  .comments-user-count .icon {
    display: none;
  }
  .singular-comment {
    display: none;
  }
  .container-users {
    margin: 1.3rem 0;
    padding-left: 6%;
    padding-bottom: 0rem;
    display: flex;
    justify-content: flex-start;
    border-bottom: none;
  }

  .submit-users-comment {
    max-width: 100%;
    width: 90%;
  }
  .container-users textarea {
    width: 95%;
    overflow: hidden;
    overflow-wrap: break-word;
    border-radius: 0.25rem;
    border-radius: var(--radius);
    background-color: #f7f8fc;
  }

  .container-users textarea::-webkit-input-placeholder {
    color: #36434d;
    font-size: 16px;
    text-transform: none;
    background-image: none;
    text-indent: 2%;
    padding-top: 0.5rem;
  }

  .container-users textarea:-ms-input-placeholder {
    color: #36434d;
    font-size: 16px;
    text-transform: none;
    background-image: none;
    text-indent: 2%;
    padding-top: 0.5rem;
  }

  .container-users textarea::placeholder {
    color: #36434d;
    font-size: 16px;
    text-transform: none;
    background-image: none;
    text-indent: 2%;
    padding-top: 0.5rem;
  }
  .submit-users-comment button {
    width: 50%;
    padding: 1rem 0;
  }
  .submit-users-comment button:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  .container-users-comments-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem 6% 0;
  }
  .container-users-comments-description div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container-users-comments-description div p {
    padding-left: 8%;
  }

  .container-users-comments-description img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

/* end of the user comments */

/* start of the section content container */
.all-content-title {
  padding-bottom: 2rem;
}
.section-contents {
  min-height: 100vh;
  max-width: 100vw;
  margin: 2rem 0 2rem 10%;
}
.section-contents-container-btn-close {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.section-contents-btn-close {
  display: block;
  height: 30px;
  width: 30px;
  color: rgba(91, 95, 112, 0.5);
  margin-right: 2rem;
  background: transparent;
}
.section-contents div h3 {
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1.25;
  color: #36434d;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
.section-contents div h3:nth-child(2) {
  display: none;
}
.section-contents div small span:nth-child(1) {
  font-size: 17px;
  line-height: 2;
  color: #5e6971;
}
.section-contents div small span:nth-child(2) {
  font-weight: 700;
  color: #5e6971;
  line-height: 2;
  font-size: 1.1rem;
  text-transform: capitalize;
}

.container-for-each-individual-course a.course-title-section {
  display: block;
  margin: 1.1rem 0;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 16px;
  color: #0af;
  color: var(--secondPrimaryColor);
}
.container-details-for-each-individual-course-Title ul li {
  margin: 0.5rem auto;
}
.container-details-for-each-individual-course-Title ul li {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  list-style: none;
  width: 100%;
  color: black;
  cursor: pointer;
}

.container-details-for-each-individual-course-Title ul li div {
  display: flex;
  align-items: center;
}
.container-details-for-each-individual-course-Title ul li div .icon {
  font-size: 2.2rem;
}
.container-details-for-each-individual-course-Title ul li div span {
  display: block;
  padding-left: 13%;
}
.container-details-for-each-individual-course-Title ul li span:hover {
  text-decoration: underline;
}

.user-watch-already {
  display: flex;
  justify-content: center;
  padding-left: 2%;
  /* text-decoration: line-through; */
}
.container-details-for-each-individual-course-Title
  ul
  li
  div
  span:nth-child(1) {
  display: flex;
}
.container-details-for-each-individual-course-Title ul li .user-watch-already {
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  padding: 0;
  margin: 0;
}

.service-provider-plateform {
  text-align: center;
  padding-bottom: 2rem;
}
.service-provider-plateform p {
  font-size: 1rem;
  color: black;
  margin: 0 auto;
}
.service-provider-plateform p span:nth-child(1) {
  text-transform: capitalize;
  color: #5e6971;
}
.service-provider-plateform p span:nth-child(3) {
  color: black;
  font-weight: 800;
  text-transform: capitalize;
}
.service-provider-plateform div span {
  font-size: 14px;
  color: #5e6971;
  margin-left: 0.4rem;
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .container-subtitle-project__child {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  .container-details-for-each-individual-course-Title ul li {
    display: grid;
    grid-template-columns: auto auto;
    list-style: none;
    justify-content: flex-start;
    width: 100%;
  }
  .section-contents div h3:nth-child(1) {
    display: none;
  }
  .section-contents div h3:nth-child(2) {
    display: block;
  }
  .section-contents div small span:nth-child(1),
  .section-contents div small span:nth-child(2) {
    display: none;
  }
  .section-contents-btn-close {
    display: none;

    background: transparent;
  }
  .user-watch-already {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 6%;
  }
  .service-provider-plateform {
    text-align: left;
    padding-left: 10%;
  }
  .service-provider-plateform div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

/* end of the contents style */

/*start of the subtitle style project */
.section-subtitle-project {
  background: #fff;
  min-height: 100vh;
}
.all-container-subtitle-project {
  width: 95%;
  margin: 3rem auto;
  border: 1px solid #e0e7ed;
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.container-subtitle-project {
  border-top: 1px solid #e0e7ed;
  padding: 2rem 0;
  width: 100%;
}

.container-subtitle-project
  .all-container-subtitle-project
  .container-subtitle-project:nth-child(2) {
  padding-top: 0px;
  border-top: 0px;
}
.all-container-subtitle-project div:nth-child(2) {
  border-top: none;
  padding-top: 0;
}

.container-subititle-project__duration {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-subititle-project__duration span {
  color: black;
  margin: 1rem 0;
  margin-left: 1rem;
}
.all-container-subtitle-project .view-video {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0af;
  background: var(--secondPrimaryColor);
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  color: white;
  width: 80%;
  margin: 0 auto;
  text-transform: capitalize;
}
.all-container-subtitle-project__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #f7f8fc;
  border-color: #e0e7ed;
  border-bottom: 1px solid #e0e7ed;
  color: #36434d;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .container-contents-sectionProject {
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .all-container-subtitle-project {
    width: 70%;
  }
  .all-container-subtitle-project .view-video {
    padding: 0.5rem 0.8rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    transition: all 0.35s ease-in-out;
  }
  .all-container-subtitle-project .view-video:hover {
    opacity: 0.8;
    -webkit-transform: translateY(-10px) scale(1.05);
            transform: translateY(-10px) scale(1.05);
  }
}

/* end of the subtitle style project*/

/* start of the product style */
/* .typeOfProduct {
  padding: 1rem;
}
.typeOfProduct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.typeOfProduct p {
  font-size: 1rem;
  text-transform: capitalize;
} */
/* end of the product style */
/* start of the mentoring program section */

.mentorship-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
}
.mentorship-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mentorship-description h4 {
  text-align: start;
  margin-bottom: 1rem;
  color: #111;
}
.mentorship-description p {
  text-align: justify;
  margin-bottom: 10px;
  color: #111;
}
@media screen and (max-width: 1000px) {
  .mentorship-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    width: 95% !important;
  }
  .mentorship-video {
    position: relative !important;
    padding-top: 56.25% !important;
  }
  .mentorship-video .React-player {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 220px !important;
    background: transparent !important;
  }
  .mentorship-video .React-player img {
    width: 100% !important;
    height: 100% !important;
  }
}
/* end of the mentoring program section */
/* star to of my contact page */
.contact-page {
  display: grid;
  place-items: center;
  margin-top: 2rem;
}
.contact-form {
  background: #fff;
  background: var(--clr-white);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
  transition: all 0.5s linear;
  transition: var(--transition);
  width: 90vw;
  max-width: 35rem;
}
.contact-form:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
}
.contact-form h3 {
  color: hsl(210, 22%, 49%);
  color: var(--clr-grey-5);
  text-align: start;
  margin: 5%;
  line-height: 1.2em;
}
.form-group {
  padding: 1rem 1.5rem;
}

.form-control {
  /* display: block; */
  display: flex;
  /* width: 100%; */
  /* padding: 0.75rem 1rem; */
  border: none;
  margin-bottom: 1.25rem;
  /* background: var(--clr-grey-10); */
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: hsl(209, 61%, 16%);
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: hsl(209, 61%, 16%);
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: hsl(209, 61%, 16%);
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.submit-btn {
  display: block;
}

/* start of the singleTutorial section  */

.video-container-tutorial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  position: relative;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  padding-top: 56.25%;
  z-index: 0 !important;
}
.video-container-tutorial .React-player {
  position: absolute;
  top: 0;
  z-index: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.global-container-video-image-title__image {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.global-container-video-image-title__image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.global-container-video-image-title__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  border-radius: 4px;
}
.global-container-video-image-title__title h1 {
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.5;
}
.global-container-video-image-title__description {
  text-align: center;
  font-size: 15px;
  color: #af9a7d;
  color: var(--primaryColor);
}

.pre-tag {
  font-size: 17px;
  width: 100%;

  max-height: -webkit-fit-content;

  max-height: -moz-fit-content;

  max-height: fit-content;
  /* overflow-y: scroll; */
  margin: 2rem 0;
}
.pre-tag pre {
  width: 100% !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.pre-tag pre code {
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

/* end of the singleTutorial  section*/

/* start of the price section */
.container-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.price-description {
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}
.container-title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-title-icon span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0.5rem;
  color: black;
}
.sub-container-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.1%;
  text-transform: capitalize;
  color: #000;
  color: #fff;
  align-self: center;
  margin-bottom: 1.3rem;
}
.sub-container-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 123.1%;
  color: #000;
  color: #fff;
  align-self: center;
  margin-bottom: 1.3rem;
}
.container-title-sign-price {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.container-title-icon {
  margin-top: 1rem;
}
.container-title-icon span {
  margin-left: 1rem;
  color: #fff;
}
.price-icon {
  font-size: 1.5rem;
  color: red !important;
  display: block;
  font-weight: 800 !important;
}
.price {
  z-index: 1;
  border: 2px solid #af9a7d;
  border: 2px solid var(--primaryColor);
  box-shadow: none;
  transition: none;
  padding: 1.5rem 1rem 2rem;
}
.price:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}
/* end of the price section */

/* start of the career path section */
.section-career,
.section-objectif {
  width: 90vw;
  margin: 0 auto;
  max-width: 1171px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 3rem;
  grid-column-gap: 2rem;
}

.container-career span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  color: #697589;
  color: #fff;
  margin-top: 1rem;
}

.container-career div img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 5px;

  background: #000;
}
@media screen and (max-width: 1000px) {
  .container-career div img {
    height: 200px;
  }
  .container-career span {
    margin-top: 0.5rem;
  }
}
/* end of the career path section */
/* start of the objectif section */
.container-objectif div img {
  height: 250px;
}

/* end fo the objectif section */
/* start of the entrepreneur section */
/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.react-player img {
  width: 100%;
  height: 100%;
} */
.container-founder-startup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.3rem;
}
.container-founder-startup span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* text-align: center; */
  color: #697589;
  margin-bottom: 0.5rem;
}
.container-founder-startup span:nth-child(1) {
  color: black;
  font-size: 18px;
  color: #fff;
  text-align: left;
}
.container-founder-title span:nth-child(1) {
  color: #697589;
  font-size: 16px;
}
.container-founder-title {
  text-align: start;
  width: 100%;
}
.container-founder-startup a {
  color: #af9a7d;
  color: var(--primaryColor);
}
/* end of the entrepreneur section */

#mentorship {
  background: white;
}

/* start of the image login */
.visible_container {
  visibility: visible;
  /* transition: var(--mainTransition); */
}
.unvisible_container {
  visibility: hidden;
  /* transition: var(--mainTransition); */
}
.container-logo-image-name-icon {
  display: flex;
  width: 130px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.container-logo-image {
  width: 40px;
  height: 40px;
}
.container-logo-image img {
  width: 100%;
  height: 100%;

  border-radius: 100%;
}
.container-logo-image-name-icon span {
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-logo-image-name-icon_icon {
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}

.container-logo-image-name-icon span:hover,
.container-logo-image-name-icon_icon:hover {
  color: #af9a7d;
  color: var(--primaryColor);
}
.container_information_user_products {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding-top: 1rem;
  top: 120px;
  right: 2%;
  -webkit-transform-origin: 260px 0px;
          transform-origin: 260px 0px;
  border-radius: 4px;
  width: 290px;
  max-width: 95%;
  width: 300px;
  background-color: #f6f9fc;
  box-shadow: 0 0 40px 5px rgb(0 0 0 / 50%);
  z-index: 9010;
}
.container_information_user_products::after,
.user_create_post::after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -20px;
  right: 20px;
}
.container_information_user_products_row1 {
  margin-top: 1.3rem;
}
.container_information_user_products_row1 span {
  color: #445d6e;
}
.container_information_user_products_row1 span:nth-child(2) {
  color: #02203c;
  font-size: 14px;
  font-weight: 600;
}
.container_information_user_products_row2 {
  color: black;
  color: #445d6e;
  font-size: 14px;
  font-weight: 600;
  margin: 1rem auto !important ;
  text-align: center;
  /* text-transform: uppercase; */
}
/* .container_information_user_products_row3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .container_information_user_products_row3 span {
  color: #445d6e;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.container_information_user_products_row3 div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.container_information_user_products_row3 div ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  margin-top: 1rem;
  width: 250px;
  max-width: 80% !important;
  width: 80%;
} */
.container_information_user_products_row3 div ul li {
  margin-top: 0.5rem;
  /* display: flex;
  
  justify-content: center;
  align-items: center; */
  /* width: 40px;
  height: 40px;
  border-radius: 50%; */
  /* background-color: gray;
  box-shadow: 0 1px 5px 0 grey; */
}
/* .container_information_user_products_row3 div ul li a {
  color: #fff;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
} */
.container_information_user_products_row4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container_information_user_products_row4 div {
  width: 40px;
  height: 40px;
}
.container_information_user_products_row4 div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.container_information_user_products_row4 {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  width: 72%;
}
.container_information_user_products_row4 span {
  color: #445d6e;
  margin: 1rem 0;
}
.container_information_user_products_row5 {
  color: #445d6e;
  text-transform: capitalize;
  font-size: 18px;
  cursor: pointer;
}
.container_information_user_products_row6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none !important;
}
.container_information_user_products_row6 .svg-logout {
  color: red;
  font-size: 2% !important ;
}
.container_information_user_products .close-btn {
  position: absolute;
  top: 0;
  right: 5%;
  color: #af9a7d;
  color: var(--primaryColor);
  text-align: end !important;
  display: block;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .container-logo-image-name-icon {
    width: 100%;
  }
  .container-logo-image {
    width: 40px;
    height: 40px;
    margin-right: 5%;
  }
}
/* end of the iamge login */
.disabled:disabled {
  pointer-events: none !important;
  cursor: default !important;
}

a.disabled {
  position: relative;
  display: inline-block;

  transition: all 0.5s ease-in-out;

  transition: var(--mainTransition);
}

a.disabled[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: 115%;
  font-size: 10px;
  text-transform: justify;
  left: 0;
}
/* start of the single blog style */
.big-container-social-share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.big-container-social-share p {
  font-weight: 800;
}
.container-social-share {
  width: 180px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-left: 4%;
}

.container-social-share div {
  width: 30px;
  height: 30px;
}
.container-social-share div img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.container-social-share button {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  translate: all 0.5s ease-in-out;
  translate: var(--mainTransition);
}
.container-social-share button:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}
@media only screen and (max-width: 900px) {
  .big-container-social-share {
    display: flex;
    flex-direction: column;
    /* justify-content:flex-start;
  align-items:center; */
    margin-top: 1rem;
  }
  .container-social-share {
    width: 180px;
    margin: 4% 0 0;
  }
}
/* end of the single blog */

/* start of the objectif section */
.section-objectif div ul {
  text-align: start;
}
.section-objectif div ul li {
  color: black;
  text-align: start;
  list-style: none;
  font-weight: 800;
}

/*start of the single project section */

/* end of the single project section */
.section-project-suggestion-project .suggestion-blog {
  border: 5px solid #0af;
  border: 5px solid var(--secondPrimaryColor);
}

.section-project-suggestion-project .suggestion-users {
  background-color: #0af;
  background-color: var(--secondPrimaryColor);
  margin: 5% 0;
  width: 100%;
  padding: 2% 0;
}

.section-project-suggestion-project .suggestion-users p {
  text-align: left;
  font-size: 18px;
  color: white !important;
}
/* start of the testimonials section */
/* start of comment section */
.global-container-signup-form-users {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.global-container-signup-form-users-show {
  opacity: 1;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.5);
}
.global-container-signup-form-users-hide {
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 1);
}
.container-submission {
  display: block;
  margin: 1rem auto 0 !important ;
}

.container-signup-form-users {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #0e2439;
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
  width: 700px;
  max-width: 100vw;
  max-height: 100vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  z-index: 100;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}

.container-signup-form-users-show {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.container-signup-form-users-hide {
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
}
/* end of comment section */
.container-signup-form-users-image-desc-title .nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
}
.container-signup-form-users-image-desc-title {
  padding-top: 1.5rem;
}
.container-signup-form-users-image-desc-title
  .nav-header
  .container-logo-title
  span {
  color: #af9a7d;
  color: var(--primaryColor);
}
.container-signup-form-users-image-desc-title h1 {
  color: #2d333a;
  color: #fff;
  margin-top: 1rem;
  font-size: 100%;
  font-weight: 800;
  font-size: 20px !important;
}
.container-signup-form-users-image-desc-title p {
  font-size: 18px !important;
  color: #fff !important;
  margin: 0.5rem auto !important;
}
.form-signup-webdevadvice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0.2rem auto 0;
  width: 100%;
}
.form-signup-webdevadvice input,
.form-signup-webdevadvice select {
  width: 95%;
  height: 3.5rem;
  margin: 0.5rem auto 0;
  padding-left: 5%;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px !important;
  text-transform: capitalize;
}
.form-signup-webdevadvice input[type="email"] {
  text-transform: none;
}

.form-signup-webdevadvice textarea {
  font-size: 16px !important;
  width: 95%;
  height: 6rem;
  margin: 0 auto;
  padding-left: 5%;
  margin-top: 1rem;
  outline: none;
  resize: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-signup-webdevadvice button {
  margin-top: 0.8rem;
  padding: 0.6rem 0.5rem;
  width: 30% !important;
}

.form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  display: block;
  font-size: 16px;
  text-transform: none;
  color: #111;
  font-weight: 600;
}

.form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  display: block;
  font-size: 16px;
  text-transform: none;
  color: #111;
  font-weight: 600;
}

.form-signup-webdevadvice input::placeholder,
textarea::placeholder {
  display: block;
  font-size: 16px;
  text-transform: none;
  color: #111;
  font-weight: 600;
}

.container_file_upload {
  width: 95%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 0 auto;
  margin-top: 4%;
  /* margin-left: 10%; */
}
.container_file_upload label {
  font-size: 16px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: #af9a7d;
  color: var(--primaryColor);
  font-weight: 800;
}
.container_file_upload input {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin: 0 !important;
  /* cursor: pointer; */
}
.container_file_upload input,
label:nth-child(1) {
  padding-left: 0 !important;
}
.global-container-signup-form-users .btn-danger-product {
  right: 2rem;
}

@media only screen and (max-width: 1280px) and (max-height: 800px) {
  .container-signup-form-users-image-desc-title .nav-header {
    padding-top: 0.8rem;
  }

  .container_file_upload {
    margin-top: 8%;
  }
  .container_file_upload label {
    font-size: 16px !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media only screen and (max-width: 1025px) and (max-height: 600px) {
  .container-signup-form-users-image-desc-title .nav-header {
    padding-top: 0.8rem;
  }
  .container-signup-form-users-image-desc-title {
    padding-top: 2rem;
  }

  .container-signup-form-users-image-desc-title h1 {
    margin-top: 0.6rem;
    font-size: 16px !important;
  }
  .container-signup-form-users-image-desc-title p {
    font-size: 16px !important;
    color: #fff !important;
    margin: 0.5rem auto 0 !important;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    width: 95%;
    height: 2.5rem;
  }

  .form-signup-webdevadvice textarea {
    height: 4rem;
    margin-top: 0.5rem;
  }

  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 12px;
  }

  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 12px;
  }

  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 12px;
  }

  .container_file_upload {
    margin-top: 4%;
  }
  .container_file_upload label {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 912px) and (max-height: 1368px) {
  .container-signup-form-users {
    width: 600px;
  }

  .container-signup-form-users-image-desc-title .nav-header {
    padding-top: 0.8rem;
  }
  .container-signup-form-users-image-desc-title {
    padding-top: 2.3rem;
  }
  .container-signup-form-users-image-desc-title h1 {
    font-size: 20px !important;
  }
  .container-signup-form-users-image-desc-title p {
    font-size: 20px !important;
    margin: 2rem auto !important;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 6rem;
  }

  .form-signup-webdevadvice textarea {
    height: 10rem;
  }
  .form-signup-webdevadvice button {
    margin-top: 1rem;
  }

  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 18px;
  }

  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 18px;
  }

  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 18px;
  }

  .container_file_upload {
    margin-top: 8%;
  }
  .container_file_upload label {
    font-size: 18px !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media only screen and (max-width: 540px) {
  .container-signup-form-users {
    min-width: 100vw;
    border: none;
  }

  .container-signup-form-users-image-desc-title .nav-header {
    padding-top: 0.8rem;
  }
  .container-signup-form-users-image-desc-title {
    padding-top: 2rem;
  }
  .container-signup-form-users-image-desc-title h1 {
    font-size: 20px !important;
  }
  .container-signup-form-users-image-desc-title p {
    font-size: 20px !important;
    margin: 1rem auto !important;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 3.5rem;
  }

  .form-signup-webdevadvice textarea {
    height: 5rem;
  }
  .form-signup-webdevadvice button {
    margin-top: 0.5rem;
  }

  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 15px;
  }

  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 15px;
  }

  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 15px;
  }

  .container_file_upload {
    margin-top: 5%;
  }
  .container_file_upload label {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 415px) {
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 4rem;
  }

  .form-signup-webdevadvice textarea {
    height: 6rem;
  }
  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 16px;
  }
  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 16px;
  }
  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 16px;
  }
}
@media only screen and (max-width: 390px) {
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 4rem;
  }
  .container_file_upload label {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 375px) {
  .container-signup-form-users-image-desc-title {
    padding-top: 2rem;
  }
  .container-signup-form-users-image-desc-title h1 {
    font-size: 18px !important;
  }
  .container-signup-form-users-image-desc-title p {
    font-size: 18px !important;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 3rem;
  }

  .form-signup-webdevadvice textarea {
    height: 5.5rem;
  }
  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 14px;
  }
  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 14px;
  }
  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 14px;
  }
}
@media only screen and (width: 360px) {
  .container-signup-form-users-image-desc-title {
    padding-top: 1rem;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 4rem;
  }

  .form-signup-webdevadvice textarea {
    height: 5rem;
  }
  .container_file_upload label {
    font-size: 11px !important;
  }
}
@media only screen and (width: 280px) {
  .container-signup-form-users-image-desc-title {
    padding-top: 0.5rem;
  }
  .form-signup-webdevadvice input,
  .form-signup-webdevadvice select {
    height: 3rem;
  }

  .form-signup-webdevadvice textarea {
    height: 4rem;
  }
  .container_file_upload label {
    font-size: 8px !important;
  }
  .container-signup-form-users-image-desc-title h1 {
    font-size: 18px !important;
  }
  .container-signup-form-users-image-desc-title p {
    font-size: 18px !important;
  }

  .form-signup-webdevadvice button {
    margin-top: 0.5rem;
  }

  .form-signup-webdevadvice input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 10px;
  }

  .form-signup-webdevadvice input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 10px;
  }

  .form-signup-webdevadvice input::placeholder,
  textarea::placeholder {
    font-size: 10px;
  }
}

/* global container post jobs */

.global-container-post-jobs {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
}
.grand-parent-global-container-post-jobs-companies {
  width: 40vw;
  background: white;
  padding-bottom: 2rem;
  border-radius: 5px;
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
  overflow-x: hidden;
}
.grand-parent-global-container-post-jobs-companies p {
  color: black;
  text-align: justify;
  text-justify: inter-word;
  color: black;
  font-weight: 800;
  padding: 0% 3% 1%;
  font-size: 17px;
}
.grand-parent-global-container-post-jobs-companies p span {
  color: #af9a7d;
  color: var(--primaryColor);
  font-weight: 800;
}
.parent-global-container-post-jobs-companies {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  max-width: 90vw;
  width: 500px;
  margin: 0 auto;
}

.parent-global-container-post-jobs-companies div {
  width: 150px;
  height: 32px;
  opacity: 1;
  max-height: 50px;
  object-fit: cover;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-global-container-post-jobs-companies div img {
  width: 100%;
  height: 100%;
}

.container-post-jobs {
  background: #0e2439;
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
  width: 50vw;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.parent-global-container-post-jobs-parents-companies {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 1rem;
}
.parent-global-container-post-jobs-parents-companies-different-options-height {
  height: 150px;
}
.parent-global-container-post-jobs-parents-companies-different-options {
  width: 100%;
  height: 150px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.parent-global-container-post-jobs-parents-companies-different-options span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}
.parent-global-container-post-jobs-parents-companies-different-options
  span:nth-child(2n + 1) {
  color: black;
}
.parent-global-container-post-jobs-parents-companies-different-options
  span:nth-child(2n) {
  color: #af9a7d;
  color: var(--primaryColor);
  margin: 2% 0;
}

.parent-global-container-post-jobs-parents-payment-methods img {
  height: 100%;
  width: 100%;
}
.parent-global-container-post-jobs-parents-payment-methods {
  color: black;
  font-size: 16px;
  font-weight: 600;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  padding-bottom: 4rem;
}
.container-user-experience-posting-jobs {
  /* width: 100%; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 1px solid #ddd;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.container-user-experience-posting-jobs
  div.container-user-experience-posting-jobs-image {
  width: 100px;
  height: 100px;
}
.container-user-experience-posting-jobs
  div.container-user-experience-posting-jobs-image
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.container-user-experience-posting-jobs-experience {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
  min-width: 40%;
}
.container-user-experience-posting-jobs-experience div.first-children {
  width: 1rem;
  display: block;
  height: 0.1rem;
  background: #111;
  font-weight: 800;
}
.container-user-experience-posting-jobs p {
  width: 80%;
  font-weight: 200;
  font-size: 15px;
}
.container-user-experience-posting-jobs div span:nth-child(2n + 1) {
  color: black;
  font-weight: 800;
}
.container-user-experience-posting-jobs div span:nth-child(2) {
  color: #af9a7d;
  color: var(--primaryColor);
  font-weight: 800;
}
.container-user-experience-posting-jobs h3 {
  color: black;
  font-weight: 550;
  margin: 0;
  padding: 0;
  font-size: 1.2rem !important;
}
.grand-parent-global-container-post-jobs-companies a {
  font-size: 14px;
  width: 60%;
}
.container_file_upload-hide-input {
  cursor: pointer;
  border: 2px solid #ddd;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #fbfbfb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin: 14px; */
  position: relative;
}
.container_file_upload-hide-input p {
  position: absolute;
  text-align: center;
  display: block;
  background: #fbfbfb;
  border-radius: 6px;
  opacity: 0.9;
  padding: 5px;
  margin: 0;
  width: 80%;
  color: #777;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container_file_upload-hide-input input {
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  width: 100% !important;
  height: 100% !important;
  font-size: 24px !important;
  opacity: 0;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.container_file_upload-hide-input:hover {
  opacity: 1;
}
@media only screen and (max-width: 1380px) {
  .global-container-post-jobs {
    display: flex;
    width: 98vw;
    justify-content: space-between;
    min-height: 100vh;
    margin: 0 auto;
  }
  .grand-parent-global-container-post-jobs-companies {
    width: 35vw;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 20px;
    line-height: 27px;
  }
  .parent-global-container-post-jobs-companies {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    max-width: 350px;
    grid-row-gap: 2rem;

    margin: 0 auto;
  }

  .parent-global-container-post-jobs-companies div {
    width: 150px;
    height: 32px;
    opacity: 1;
    max-height: 50px;
    object-fit: cover;
  }

  .container-post-jobs {
    width: 60%;
  }
  .container-post-jobs input {
    height: 80px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 20px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 20px;
  }
  .container-post-jobs input::placeholder {
    font-size: 20px;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 20px;
  }

  .container-user-experience-posting-jobs
    div.container-user-experience-posting-jobs-image {
    width: 120px;
    height: 120px;
  }
  .container-user-experience-posting-jobs
    div.container-user-experience-posting-jobs-image
    img {
    border-radius: 100%;
  }
  .container-user-experience-posting-jobs-experience {
    width: 60%;
    max-width: 80%;
  }
  .container-user-experience-posting-jobs-experience div.first-children {
    height: 0.2rem;
  }
  .container-user-experience-posting-jobs p {
    width: 90%;

    font-size: 17px;
  }

  .container-user-experience-posting-jobs h3 {
    font-size: 1.5rem !important;
  }
  .grand-parent-global-container-post-jobs-companies a {
    font-size: 17px;
    width: 80%;
  }
  .container_file_upload-hide-input {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (max-width: 1025px) {
  .global-container-post-jobs {
    display: flex;
    width: 98vw;
  }
  .grand-parent-global-container-post-jobs-companies {
    width: 35vw;
  }
  .container-post-jobs input {
    height: 70px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 18px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 18px;
  }
  .container-post-jobs input::placeholder {
    font-size: 18px;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 912px) {
  .global-container-post-jobs {
    display: flex;
    width: 100vw;
  }
  .grand-parent-global-container-post-jobs-companies {
    width: 28vw;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 20px;
    line-height: 27px;
  }

  .parent-global-container-post-jobs-companies {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-row-gap: 2rem;
    width: 90vw;
    max-width: 170px;
    margin: 0 auto;
  }

  .container-post-jobs {
    width: 70vw;
  }
  .container-post-jobs input {
    height: 80px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 20px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 20px;
  }
  .container-post-jobs input::placeholder {
    font-size: 20px;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 18px;
  }

  .container-user-experience-posting-jobs
    div.container-user-experience-posting-jobs-image {
    width: 140px;
    height: 140px;
  }

  .container-user-experience-posting-jobs-experience {
    width: 80%;
  }

  .container-user-experience-posting-jobs p {
    width: 90%;

    font-size: 19px !important;
    line-height: 28px;
  }

  .container-user-experience-posting-jobs h3 {
    font-size: 1.2rem !important;
    line-height: 26px;
  }
  .grand-parent-global-container-post-jobs-companies a {
    font-size: 15px;
    width: 90%;
  }
  .container_file_upload-hide-input {
    width: 130px;
    height: 130px;
  }

  .container-post-jobs input {
    height: 80px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 18px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 18px;
  }
  .container-post-jobs input::placeholder {
    font-size: 18px;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .global-container-post-jobs {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .grand-parent-global-container-post-jobs-companies {
    width: 90vw;
    margin: 2rem auto;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .parent-global-container-post-jobs-companies {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-row-gap: 2rem;
    width: 90vw;
    max-width: 500px;
    margin: 0 auto;
  }

  .parent-global-container-post-jobs-companies div {
    max-width: 130px;
  }

  .container-post-jobs {
    width: 90vw;
    margin: 0 auto;
  }
  .container-post-jobs input {
    height: 80px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 21px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 21px;
  }
  .container-post-jobs input::placeholder {
    font-size: 21px;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 20px;
  }

  .container-user-experience-posting-jobs
    div.container-user-experience-posting-jobs-image {
    width: 150px;
    height: 150px;
  }

  .container-user-experience-posting-jobs-experience {
    width: 60%;
  }

  .container-user-experience-posting-jobs p {
    width: 90%;

    font-size: 21px !important;
    line-height: 28px;
  }

  .container-user-experience-posting-jobs h3 {
    font-size: 1.5rem !important;
    line-height: 26px;
  }
  .grand-parent-global-container-post-jobs-companies a {
    font-size: 18px;
    width: 90%;
  }
}

@media only screen and (max-width: 540px) {
  .grand-parent-global-container-post-jobs-companies {
    width: 95vw;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .container-post-jobs {
    width: 99vw;
  }
  .container-post-jobs input {
    height: 80px;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 16px;
  }
  .container-post-jobs input::placeholder {
    font-size: 16px;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 20px;
  }

  .container-user-experience-posting-jobs-experience {
    width: 65%;
  }

  .container-user-experience-posting-jobs p {
    width: 95%;

    font-size: 18px !important;
  }

  .container-user-experience-posting-jobs h3 {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 415px) {
  .grand-parent-global-container-post-jobs-companies {
    width: 100vw;
  }
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .container-post-jobs {
    width: 100vw;
  }
  .container-post-jobs input {
    height: 90px;
    width: 99%;
    padding-left: 1%;
  }
  .container-post-jobs input::-webkit-input-placeholder {
    font-size: 15px;
  }
  .container-post-jobs input:-ms-input-placeholder {
    font-size: 15px;
  }
  .container-post-jobs input::placeholder {
    font-size: 15px;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 18px;
  }

  .container-user-experience-posting-jobs-experience {
    width: 70%;
  }
  .container-user-experience-posting-jobs p {
    width: 94%;
    font-size: 18px !important;
  }

  .grand-parent-global-container-post-jobs-companies a {
    font-size: 15px;
    width: 90%;
  }
}

@media only screen and (max-width: 280px) {
  .grand-parent-global-container-post-jobs-companies p {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .parent-global-container-post-jobs-parents-payment-methods {
    font-size: 17px;
  }

  .container-user-experience-posting-jobs-experience {
    width: 70%;
  }

  .container-user-experience-posting-jobs p {
    width: 94%;
    /* font-weight: 200; */
    font-size: 15px !important;
    line-height: 27px !important;
  }

  .container-user-experience-posting-jobs h3 {
    font-size: 1.2rem !important;
    line-height: 23px !important;
  }
  .grand-parent-global-container-post-jobs-companies a {
    font-size: 13px !important;
  }
}

/*end global container-post job */

/* start the slider job */
.section-jobs-description {
  width: 90vw;
  max-width: 1171px;

  margin: 0 auto;
}
.section-jobs-description p {
  line-height: 28px;
  font-weight: 600;
}
.company-logo-articles {
  /* background: #fff !important;
   */
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  width: 95vw;
  max-width: 95vw !important;
  overflow: hidden;
  z-index: 1;
  height: 200px;
  margin: 1rem auto 0 !important;
}

.container-company-logo-article {
  position: relative;
  margin: 0rem auto !important;
  height: 100%;
}
.company-logo-articles-side {
  position: absolute;
  top: 0;
  width: 40px;
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
}
.company-logo-articles-side1 {
  left: 0;
  background-image: linear-gradient(90deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.company-logo-articles-side2 {
  right: 0;
  background-image: linear-gradient(270deg, #fff 90%, hsla(0, 0%, 100%, 0));
}
.company-logo-articles-container {
  width: 1614px !important;
  position: relative;
  top: 0;
  left: 0;
  overflow: visible;
  height: 100% !important;
  margin: 0 auto !important;
}
.company-logo-articles-container-logo {
  display: grid;
  grid-template-columns: repeat(7, 200px);
  grid-column-gap: 32px;
  margin: 0 auto;
  margin-top: 2rem;
  position: absolute;
}
@keyframes slide1 {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.company-logo-articles-container-logo1-companies {
  -webkit-animation: slide1 50s linear 0s infinite;
          animation: slide1 50s linear 0s infinite;
  top: 0;
}
@keyframes slide2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.company-logo-articles-container-logo2-companies {
  top: 0;
  -webkit-animation: slide2 50s linear 0s infinite;
          animation: slide2 50s linear 0s infinite;
}

.company-logo-articles-container-logo div {
  width: 200px;
  height: 150px;
}
.company-logo-articles-container-logo div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  opacity: 1;
}
.opacity-companies {
  opacity: 1 !important ;
  -webkit-filter: grayscale(100%) brightness(100%) !important;
  filter: grayscale(100%) brightness(59%) !important;
}

/*end of the silder job */

/*end of the testimonials section */

/* start affiliate product */

.section-center-affiliate {
  width: 60vw;

  max-width: 1171px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-row-gap: 3rem;
  grid-column-gap: 2rem;
}
.full-container-affiliate-product {
  background-color: #000;
  border: 5px solid #af9a7d;
  border: 5px solid var(--primaryColor);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.full-container-affiliate-product:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.container-affiliate-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
}
.container-affiliate-product div {
  width: 116px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.container-affiliate-product div img {
  width: 100%;
  height: 100%;
}
.container-affiliate-product p {
  color: #fff;
  font-weight: 800;
  text-transform: capitalize;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  z-index: 1;
  margin: 0 auto;
}
/* end of the affiliate product */

/* end container pop up product and description */

/* start of the job */
.section-jobs {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.full-container-jobsListing-jobPosts-email-subscription {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  min-height: 100vh;
  margin: 2rem auto;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  width: 90vw;
  margin-top: 2rem;
  height: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  border-radius: 10px;
  padding: 2% 2%;
  border: 2px solid #af9a7d;
  border: 2px solid var(--primaryColor);
  box-shadow: 1px 2px 4px rgb(0 0 0 / 3%);
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description {
  margin-left: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
  h6 {
  font-size: 1.4rem;
  padding-top: 2%;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
  p {
  font-size: 1rem;
  color: #21293c;
  color: #af9a7d;
  color: var(--primaryColor);
  padding-top: 2%;
  font-weight: 800;
  text-transform: capitalize;
}

.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
  div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description-salary {
  display: flex;
  padding-top: 2%;
  justify-content: flex-start !important;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description-salary-pay {
  margin-left: 2%;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
  div
  span {
  color: black;
  font-size: 0.8rem;
  color: #21293c !important;
  font-weight: 600;
  text-transform: capitalize;
}

.full-container-jobsListing-jobPosts-email-subscription__jobslisting-time-apply-worldwide {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  text-transform: capitalize;
}

.full-container-jobsListing-jobPosts-email-subscription__jobslisting-apply {
  color: black;
  color: #21293c;
  font-size: 0.9rem;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-container-stack
  span {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 10px;
  font-size: 13px;
  color: #0f1115;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-publishTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #0f1115;
  text-transform: capitalize;
}
@media only screen and (max-width: 1281px) {
  .full-container-jobsListing-jobPosts-email-subscription {
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    width: 98vw;
    min-height: 100vh;
    margin: 0rem auto;
    padding: 0;
  }
}

@media only screen and (max-width: 1281px) {
  .section {
    margin: 0 auto;
  }
  .full-container-jobsListing-jobPosts-email-subscription {
    display: flex;
    margin: 1rem auto;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting {
    max-width: 98vw;
    width: 95%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 3% 2%;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-margin {
    margin-top: 0rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo-description {
    display: flex;
    align-items: center;
    width: 400px;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo {
    width: 150px;
    height: 150px;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    h6 {
    font-size: 1.3rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    p {
    font-size: 1.2rem;
    color: #21293c;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    display: inline-block;
    font-size: 1rem;
    margin-top: 0.3rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-time-apply-worldwide {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 821px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting {
    max-width: 98vw;
    width: 95vw;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 3% 2%;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo-description {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    h6 {
    font-size: 1.1rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    p {
    font-size: 1rem;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 541px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3% 2%;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo-description {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    h6 {
    font-size: 1.4rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    p {
    font-size: 1.3rem;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
}
@media only screen and (max-width: 415px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo {
    width: 90px;
    height: 90px;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    h6 {
    font-size: 1.3rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    p {
    font-size: 1.1rem;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 395px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 361px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 0.6rem;
  }
}
@media only screen and (max-width: 281px) {
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-logo {
    width: 70px;
    height: 70px;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    h6 {
    font-size: 0.9rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    p {
    font-size: 0.7rem;
  }

  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-description
    div
    span {
    font-size: 0.55rem;
  }
}
/* end of the job */
/* start of the jobposted section */
.containerPostjobs-jobCategories-emails {
  max-width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost-outter {
  /* display: flex; */
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0.1%;
  min-width: 300px;
  min-height: 140px;
  background: #af9a7d;
  background: var(--primaryColor);
  background: #fff;
  border-radius: 5px;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 100%;
  display: flex;
  margin: 10% auto 0;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost-logo {
  width: 100px;
  height: 100px;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost-logo img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost h5 {
  color: #111;
  font-weight: 600;
}
.full-container-jobsListing-jobPosts-email-subscription-jobPost p {
  color: #111;
  font-weight: 600;
}
.full-container-jobsListing-jobPosts-email-subscription-hiring-advertising {
  padding-left: 10%;
}
@media only screen and (max-width: 1281px) {
  .containerPostjobs-jobCategories-emails {
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
  }
  .full-container-jobsListing-jobPosts-email-subscription-jobPost-outter {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .full-container-jobsListing-jobPosts-email-subscription-jobPost {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding: 5%;
  }
  .full-container-jobsListing-jobPosts-email-subscription-jobPost-logo {
    width: 100px;
    height: 100px;
  }
  .full-container-jobsListing-jobPosts-email-subscription-jobPost p {
    color: #111;
    font-size: 1.3rem;
  }
}
/*end fo the jobposted section */

/*start of the filtering section */
.full-container-jobsListing-jobPosts-email-subscription-filtering-all {
  margin: 0 auto;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-all h5 {
  margin-bottom: 1rem;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs {
  min-width: 300px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  background: #fff;
  margin: 0 auto 0;
  width: 100%;
  border-radius: 5px;
  padding: 1rem 5%;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 15px;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label
  label {
  color: #111;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0;
  padding-left: 5%;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  h5 {
  color: black !important;
  margin-top: 1rem;
  margin-bottom: 0;
  text-transform: capitalize;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  .location-filter {
  width: 70%;
  height: 30px;
  border: 0.3px solid #af9a7d;
  border: 0.3px solid var(--primaryColor);
  outline: none;
  border-radius: 3px;
  display: block;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  padding-left: 11% !important;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  .location-filter:hover {
  border: 0.8px solid #af9a7d;
  border: 0.8px solid var(--primaryColor);
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  .location-filter::-webkit-input-placeholder {
  font-size: 12px;
  /* padding-left: 11% !important; */
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  .location-filter:-ms-input-placeholder {
  font-size: 12px;
  /* padding-left: 11% !important; */
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
  form
  div
  .location-filter::placeholder {
  font-size: 12px;
  /* padding-left: 11% !important; */
}
.container-filtering-location {
  position: relative;
  /* background: red; */
  width: 100%;
  height: 100%;
}
.container-filtering-location-icon {
  position: absolute;
  color: #111;
  /* background: red; */
  top: 20%;
  left: 60%;

  cursor: pointer;
}
.container-filtering-location-state {
  position: absolute;

  width: 70%;
  height: 200px;
  background: #fff;
  z-index: 1000;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.container-filtering-location-state-show {
  display: block;
}
.container-filtering-location-state-hide {
  display: none;
}
.container-filtering-location-country-show {
  display: block;
}
.container-filtering-location-country-hide {
  display: none;
}

.container-filtering-location-state ul {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  font-size: 14px;
  text-align: left;
  color: #445d6e;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-filtering-location-state ul li {
  /* background: #333; */
  width: 100%;
  cursor: default;
  height: 30px;
  color: #333;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 5% 0 5% 8%;
  cursor: pointer;
}
.container-filtering-location-state ul li:hover {
  width: 100%;
  background: #ccc;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label
  .price-filter {
  width: 25%;
  height: 30px;
  border: 0.3px solid #af9a7d;
  border: 0.3px solid var(--primaryColor);
  outline: none;
  border-radius: 3px;
  display: block;
  margin-top: 0.5rem;
  padding-left: 5%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  margin: 0;
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label
  .price-filter:hover {
  border: 0.8px solid #af9a7d;
  border: 0.8px solid var(--primaryColor);
}
.full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label
  .price-filter:nth-child(2) {
  margin-left: 5%;
}
/*end of the post job section*/

/*start of the filtering section */
/* @media only screen and (max-width: 1281px) {
  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs {
    max-width: 100%;
    width: 100%;
    min-height: fit-content;
    padding: 2%;
  }

  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label {
    align-items: center;
    margin-top: 15px;
  }
  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs-input-label
    label {
    color: #111;
    font-size: 1.2rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
    form
    div
    h5 {
    color: black !important;
    margin-top: 1.3rem;
  }
  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
    form
    div
    .location-filter {
    width: 90%;
    height: 50px;
    border: 0.5px solid var(--primaryColor);
    margin-top: 0.8rem;
  }

  .full-container-jobsListing-jobPosts-email-subscription-filtering-jobs
    form
    div
    .location-filter::placeholder {
    font-size: 16px;
    
  }

  .container-filtering-location-icon {
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);

    cursor: pointer;
  }
  .container-filtering-location-state {
    width: 90%;
  }
} */
/* start of the email blog job section  */
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  border-radius: 4px;
  padding: 1.5rem 0;
  padding-left: 5%;
  margin-top: 2rem;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email h5 {
  color: #111;
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  form
  .filterEmail {
  width: 70%;
  height: 30px;
  border: 0.3px solid #af9a7d;
  border: 0.3px solid var(--primaryColor);
  outline: none;
  border-radius: 3px;
  display: block;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  font-size: 12px !important;
  padding-left: 11% !important;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  form
  .filterEmail::-webkit-input-placeholder {
  font-size: 12px !important;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  form
  .filterEmail:-ms-input-placeholder {
  font-size: 12px !important;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  form
  .filterEmail::placeholder {
  font-size: 12px !important;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  form
  .filterEmail:hover {
  border: 0.8px solid #af9a7d;
  border: 0.8px solid var(--primaryColor);
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email div {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
}
.full-container-jobsListing-jobPosts-email-subscription__jobslisting-email
  div
  label {
  color: #111;
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 5%;
}

@media only screen and (max-width: 1025px) {
  .containerPostjobs-jobCategories-emails {
    width: 33%;
  }
}

@media only screen and (max-width: 913px) {
  .full-container-jobsListing-jobPosts-email-subscription {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 99vw;
    width: 100vw;
  }
}
@media only screen and (max-width: 913px) {
  .containerPostjobs-jobCategories-emails {
    max-width: 99vw;
    width: 99vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #fff;
    padding: 2%;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
    display: none;
  }
}

@media only screen and (max-width: 541px) {
  .containerPostjobs-jobCategories-emails {
    flex-direction: column;
    background: none;
    width: 100vw;
  }
  .full-container-jobsListing-jobPosts-email-subscription-jobPost-outter {
    width: 100%;
  }
  .full-container-jobsListing-jobPosts-email-subscription__jobslisting-email {
    display: none;
  }

  .full-container-jobsListing-jobPosts-email-subscription-filtering-all {
    width: 100%;
  }
}
@media only screen and (max-width: 282px) {
}

/*end of the email of blogs section */
/* start of login and signup page css */
.signup-with-your-favorite-social-network {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.signup-with-your-favorite-social-network_container_option {
  max-width: 100%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5% auto;
}
.signup-with-your-favorite-social-network_container_option span:first-child,
.signup-with-your-favorite-social-network_container_option span:last-child {
  width: 45%;
  height: 2px;
  margin: 0;
  padding: 0;
  background: #af9a7d;
  background: var(--primaryColor);
}
.signup-with-your-favorite-social-network_row {
  display: flex;
  width: 95%;
  max-width: 95%;
  justify-content: space-between;
  align-items: center;
  margin: 2% auto;
}
.signup-with-your-favorite-social-network_row div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 40px;

  border-radius: 4px;
  font-size: 14px;
  padding: 0 1%;
  overflow: hidden;
  color: #fff;
  font-weight: 700;
  background: #4285f4;
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.signup-with-your-favorite-social-network_row div:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.signup-with-your-favorite-social-network_row_icon {
  width: 30px;
  height: 100%;
  margin-right: 2%;
}

.signup-with-your-favorite-social-network_row div:nth-child(2) {
  background-color: #252525;
}
.signup-with-your-favorite-social-network_row div:nth-child(4) {
  background: #0072b1;
}
.signup-with-your-favorite-social-network_row div span {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* end of the login and signup page */
/* start a  brand new comment section */
.parent-global-container-comments-user {
  width: 100%;
  margin-top: 2rem;
}
.global-container-comments-user {
  margin: 2rem 2% 2rem 7%;
}
.parent-global-container-comments-user form {
  flex-direction: column;
}
.global-container-comments-user textarea {
  width: 100%;
  height: 17rem;
  resize: none;
  border-radius: 4px;
  background-color: #f8f8f8 !important;
  font-size: 16px;
  overflow: hidden !important;
  padding: 12px 20px;
  outline: none;
}
.global-container-comments-user__border_counterComments {
  color: #af9a7d;
  color: var(--primaryColor);
  padding-left: 7%;
}
.global-container-comments-user_border_top {
  width: 3rem;
  height: 2px;
  background: #af9a7d;
  background: var(--primaryColor);
  margin-bottom: 1rem;
}
.global-container-comments-user__container_profile_author_date {
  display: flex;

  align-items: center;
}
.global-container-comments-user__container_profile {
  width: 50px;
  height: 50px;
}
.global-container-comments-user__container_profile img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.global-container-comments-user__container_author_date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2%;
  height: 50px;
}
.global-container-comments-user__container_author_date span:nth-child(2) {
  color: #af9a7d;
  color: var(--primaryColor);
}
.global-container-comments-user_comments {
  margin-top: 1rem;
}
.global-container-comments-user_comments p {
  /* line-height: 2; */
  color: #d1dbe6;
  white-space: pre-line !important;
}

.global-container-comments-user_like_reply_edit_delete_share
  button:nth-child(n + 2) {
  margin-left: 1% !important;
}
.global-container-comments-user_like_reply_edit_delete_share button {
  font-size: 10px !important;
  padding: 5px !important;
}
.global-container-comments-user__reply {
  margin-left: 7%;
  width: 93%;
}

.global-container-comments-user__reply__reply {
  margin-left: 7%;
  width: 93%;
}

/* end a brand new comment section */
.container-category-posts span {
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
}
.container-category-posts span:hover {
  /* border-bottom: 2px solid var(--primaryColor);
  padding-bottom: 2px; */
  opacity: 0.8;
  font-weight: bold;
}
.discussion-container-users {
  padding: 1rem;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
  transition: all 0.5s ease-in-out;
  transition: var(--mainTransition);
  /* background: #162c41; */
  height: 160px;
  position: relative;
}

